import React, { useState } from "react";
import {
  Nav,
  Navbar,
  NavDropdown,
  Col,
  Row,
  Container,
  Dropdown,
  Modal,
  Button,
} from "react-bootstrap";
import close from "../assets/iclose.svg";
import img1 from "./Docuflow.png";
import img2 from "./Docuflow2.png";
import img3 from "../assets/Lending.png";
import "./Product.scss";
import Contact from "../Contact";
import { useNavigate } from "react-router-dom";
import { isAuthenticated } from "../utils/auth";
import Swal from "sweetalert2";
function Docuflow() {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <>
      <div className="product">
        <div className="product1">
          <div className="product1left">
            <p className="headingproduct">Onboarding APIs</p>
            <p className="contentproduct">
              Streamline your document management with Onboarding API, an all-in-one documentation solution. Effortlessly create, edit, and sign documents using diverse templates.
            </p>
            <div style={{
              display: 'flex',
              flexDirection: "row",
              width: '100%'
            }}>
              <button
                eventKey="13"
                style={{ cursor: "pointer", marginRight: "50px" }}
                onClick={() => {

                  // navigate("/api/docuflow");
                  isAuthenticated() ?
                    window.open("/api/docuflow", '_blank')
                    : Swal.fire({
                      position: "top-end",
                      icon: "info",
                      text: "Please Login to Try out the APIs",
                      showConfirmButton: false,
                      timer: 1500
                      , heightAuto: false
                    });;
                }}
                data-toggle="modal"
                className="prodbuttonx"
              >
                Try out APIs
              </button>
              <button
                eventKey="13"
                style={!isAuthenticated() ? { display: "none" } : { cursor: "pointer" }}
                onClick={() => {
                  window.open('https://api-doc.trusthub.in/latest-zips/onboarding_apis.zip', '_blank'); // '
                }}
                data-toggle="modal"
                className="prodbuttonx2"
              >
                Download PDF
              </button>
            </div>

          </div>
          <div className="product1right">
            <div className="contentwrapproductleft">
              <p className="heading3product">Key Features of Onboarding APIs</p>
              <ul>
                <ol>
                  <svg
                    width="20"
                    height="15"
                    viewBox="0 0 20 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M19.4648 2.01664L6.71392 14.7675L0.498047 8.55164L2.03813 7.01156L6.71392 11.6873L17.9247 0.476562L19.4648 2.01664Z"
                      fill="#1D6AE5"
                    />
                  </svg>
                  &nbsp; Agreement Generation
                </ol>
                <ol>
                  <svg
                    width="20"
                    height="15"
                    viewBox="0 0 20 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M19.4648 2.01664L6.71392 14.7675L0.498047 8.55164L2.03813 7.01156L6.71392 11.6873L17.9247 0.476562L19.4648 2.01664Z"
                      fill="#1D6AE5"
                    />
                  </svg>
                  &nbsp; Diverse Document Templates
                </ol>
                <ol>
                  <svg
                    width="20"
                    height="15"
                    viewBox="0 0 20 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M19.4648 2.01664L6.71392 14.7675L0.498047 8.55164L2.03813 7.01156L6.71392 11.6873L17.9247 0.476562L19.4648 2.01664Z"
                      fill="#1D6AE5"
                    />
                  </svg>
                  &nbsp; E-Signing & E-Stamping
                </ol>
                <ol>
                  <svg
                    width="20"
                    height="15"
                    viewBox="0 0 20 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M19.4648 2.01664L6.71392 14.7675L0.498047 8.55164L2.03813 7.01156L6.71392 11.6873L17.9247 0.476562L19.4648 2.01664Z"
                      fill="#1D6AE5"
                    />
                  </svg>
                  &nbsp; Signatory Customization
                </ol>
                <ol>
                  <svg
                    width="20"
                    height="15"
                    viewBox="0 0 20 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >

                  </svg>
                  &nbsp; and more...
                </ol>
              </ul>
            </div>
          </div>
        </div>
        <div className="product2">
          <div className="product2left">
            <div className="contentwrapproductleftx">
              <p className="heading2product">
                Onboarding API simplifies document management by offering a comprehensive solution for deal creation, e-signing, and agreement generation. Customize document signatories on the fly, extract or generate PDFs with a click.
              </p>
              <p className="content2product">
                Enjoy the convenience of e-signing and e-stamping features. With a complete document management system and a template directory, Docuflow makes documentation inviting and invigorating, saving your business time and resources.
              </p>
            </div>
          </div>
        </div>



      </div>
      <Modal
        className="navmodal"
        show={show}
        backdrop="static"
        onHide={handleClose}
        size="lg"
        centered
      >
        {/* <Modal.Header closeButton><ModalTitle>test</ModalTitle></Modal.Header> */}
        <div class="d-flex justify-content-end">
          <Button
            style={{
              backgroundColor: "#64bb96",
              borderRadius: "50%",
              height: "50px",
              width: "50px",
            }}
            className="modalbutton mr-auto "
            variant="secondary"
            onClick={handleClose}
          >
            <img
              style={{
                height: "100%",
                width: "100%",
                backgroundColor: "#64bb96",
              }}
              src={close}
              alt=""
            />
          </Button>
        </div>
        <Contact />
      </Modal>
    </>
  );
}

export default Docuflow;
