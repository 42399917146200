import React, { useState } from "react";
import "./NewsSection.css";
import Indorient from "./assets/Logo/Indorient.png";
const NewsSection2 = () => {
  const [darkMode, setDarkMode] = useState(false);

  const toggleMode = () => {
    setDarkMode(!darkMode);
  };

  // Card data
  const cards = [
    {
      image: "https://drive.google.com/uc?id=1dMNf5bssuotNJRktmIVhMrq3jmYe5Tb6",
      tags: [],
      title:
        "Fintech Yatra 2023 : Paving Fintech's next decade of growth         ",
      category: "",
      categoryLink: "#",
      text: "",
      date: "2023",
      readMoreLink: "https://yatra.connexdoor.com/",
    },
    {
      image: "https://drive.google.com/uc?id=19_k9hgxRtd9vJLSegWKAUdl_hCHKbHIN",
      tags: [],
      title:
        "Glad to announce TransBnk - Transaction Banking Platform has joined #FintechYatra2023 as our Transaction Banking Partner.",
      category: "",
      categoryLink: "#",
      text: "",
      date: "2023",
      readMoreLink:
        "https://www.linkedin.com/posts/the-fintech-meetup_fintechyatra2023-fintechyatra-fintech-activity-7030036981212430336-01aK?utm_source=share&utm_medium=member_desktop",
    },
    {
      image: "https://drive.google.com/uc?id=13j3kuPKWfNyGsz5O0sf3ZnRYPUXz5NDX",
      tags: [],
      title:
        "Looking forward to meeting some of the brightest ideas in Upcoming Startup mixer event in Bangalore!",
      category: "",
      categoryLink: "#",
      text: "",
      date: "2023",
      readMoreLink:
        "https://www.linkedin.com/posts/vaibhav-tambe_bangalore-startup-fintech-activity-7034225133716135936-j9gW?utm_source=share&utm_medium=member_desktop",
    },
    {
      image: "https://drive.google.com/uc?id=1n7rrLFR6iiFFyHU8a9bLiQKopp57fC4A",
      tags: [],
      title: "Our CEO is attending the Chennai Fintech Meetup!",
      category: "",
      categoryLink: "#",
      text: "",
      date: "2023",
      readMoreLink:
        "https://www.linkedin.com/feed/update/urn:li:activity:7071716369511153664",
    },
    {
      image: "https://drive.google.com/uc?id=1gLoImoCgTk2dk_OG354XiAdmegQNVm62",
      tags: [],
      title: "Fintech Yatra represents TransBnk at the Everest Base Camp",
      category: "",
      categoryLink: "#",
      text: "",
      date: "2023",
      readMoreLink:
        "https://www.linkedin.com/feed/update/urn:li:activity:7052510635284172800",
    },
  ];

  return (
    <section className={`focus-section ${darkMode ? "dark-mode" : ""}`}>
      <div className="container-lg py-2">
        <button
          className={`btn btn-${
            darkMode ? "light" : "dark"
          } btn-sm text-uppercase`}
          onClick={toggleMode}
          id="btnValue"
          type="button"
        >
          {darkMode ? "Light mode" : "Dark mode"}
        </button>
      </div>

      <div className="container-lg py-5">
        <div className="container px-0">
          <div className="row">
            <div className="col">
              <h2 className="title-section">Fintech Yatra 2023</h2>
            </div>
            {/* <div className="col">
              {/* <span className="title-section d-flex justify-content-end">
                <a className="btn btn-archive btn-sm" href="#" role="button">
                  Archive
                </a>
              </span> 
            </div> */}
          </div>
        </div>

        <div className="row row-cols-1 row-cols-md-3 g-4">
          {cards.map((card, index) => (
            <div className="col" key={index}>
              <div className="card shadow-sm h-100">
                <div className="card-image">
                  <div className="hover-text">
                    <img src={card.image} className="card-img-top" alt="..." />
                    <div className="bottom-right-tag text-uppercase">
                      {card.tags.map((tag, index) => (
                        <a key={index} href="#">
                          {tag}
                        </a>
                      ))}
                    </div>
                  </div>
                  <div className="image-overlay"></div>
                </div>
                <div className="card-body">
                  <h3 className="card-title">{card.title}</h3>
                  <div className="text-left my-2">
                    {card.category && (
                      <div className="sub-cat text-truncate">
                        <span className="badge rounded-pill bg-category text-uppercase">
                          {card.category}
                        </span>{" "}
                        <a href={card.categoryLink}>{card.category}</a>
                      </div>
                    )}
                  </div>
                  <p className="card-text">{card.text}</p>
                </div>
                <div className="card-footer py-3">
                  <div className="card-footer__info">
                    <span>{card.date}</span>
                    <span className="read-more">
                      <a
                        className="text-uppercase read-more-1"
                        href={card.readMoreLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Read more
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default NewsSection2;
