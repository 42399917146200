import React, { useState } from "react";
import {
  Nav,
  Navbar,
  NavDropdown,
  Col,
  Row,
  Container,
  Dropdown,
  Modal,
  Button,
} from "react-bootstrap";
import close from "../assets/iclose.svg";
import img3 from "../assets/Lending.png";
import "./Product.scss";
import Contact from "../Contact";
import { useNavigate } from "react-router-dom";
import { isAuthenticated } from "../utils/auth";
import Swal from 'sweetalert2'
function Payout() {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <>
      <div className="product">
        <div className="product1">
          <div className="product1left">
            <p className="headingproduct">Recurring Payment<br />APIs</p>
            <p className="contentproduct">
              Automate recurring payments effortlessly with our Recurring Payment API, integrating NACH and UPI Autopay for seamless financial management.
            </p>
            <div style={{
              display: 'flex',
              flexDirection: "row",
              width: '100%'
            }}>
              <button
                eventKey="13"
                style={{ cursor: "pointer", marginRight: "50px" }}
                onClick={() => {

                  isAuthenticated() ?
                    window.open("/api/payments", '_blank')
                    : Swal.fire({
                      position: "top-end",
                      icon: "info",
                      text: "Please Login to Try out the APIs",
                      showConfirmButton: false,
                      timer: 1500
                      , heightAuto: false
                    });;
                }}
                data-toggle="modal"
                className="prodbuttonx"
              >
                Try out APIs
              </button>
              <button
                eventKey="13"
                style={!isAuthenticated() ? { display: "none" } : { cursor: "pointer" }}
                onClick={() => {
                  window.open('https://api-doc.trusthub.in/latest-zips/recurring_payment_apis.zip', '_blank'); // '
                }}
                data-toggle="modal"
                className="prodbuttonx2"
              >
                Download PDF
              </button>
            </div>
          </div>
          <div className="product1right">
            <div className="contentwrapproductleft">
              <p className="heading3product">Key Features of Recurring Payment APIs</p>
              <ul>
                <ol>
                  <svg
                    width="20"
                    height="15"
                    viewBox="0 0 20 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M19.4648 2.01664L6.71392 14.7675L0.498047 8.55164L2.03813 7.01156L6.71392 11.6873L17.9247 0.476562L19.4648 2.01664Z"
                      fill="#1D6AE5"
                    />
                  </svg>
                  &nbsp; Mandate Generation
                </ol>
                <ol>
                  <svg
                    width="20"
                    height="15"
                    viewBox="0 0 20 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M19.4648 2.01664L6.71392 14.7675L0.498047 8.55164L2.03813 7.01156L6.71392 11.6873L17.9247 0.476562L19.4648 2.01664Z"
                      fill="#1D6AE5"
                    />
                  </svg>
                  &nbsp; NACH Presentation
                </ol>
                <ol>
                  <svg
                    width="20"
                    height="15"
                    viewBox="0 0 20 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M19.4648 2.01664L6.71392 14.7675L0.498047 8.55164L2.03813 7.01156L6.71392 11.6873L17.9247 0.476562L19.4648 2.01664Z"
                      fill="#1D6AE5"
                    />
                  </svg>
                  &nbsp; BBPS API
                </ol>

              </ul>
            </div>
          </div>
        </div>
        <div className="product2">
          <div className="product2left">
            <div className="contentwrapproductleftx">
              <p className="heading2product">
                Simplify Recurring Payment creation through NACH and UPI Autopay integrations with our versatile API. Automate recurring payments effortlessly using mandate requests and dashboards.
              </p>
              <p className="content2product">
                Monitor real-time status and streamline NACH presentation with a user-friendly dashboard. Uncomplicate bill payments with our dedicated BBPS API, ensuring efficient collection and prompt payment confirmations for your business.
              </p>
            </div>
          </div>
        </div>



      </div>
      <Modal
        className="navmodal"
        show={show}
        backdrop="static"
        onHide={handleClose}
        size="lg"
        centered
      >
        {/* <Modal.Header closeButton><ModalTitle>test</ModalTitle></Modal.Header> */}
        <div class="d-flex justify-content-end">
          <Button
            style={{
              backgroundColor: "#64bb96",
              borderRadius: "50%",
              height: "50px",
              width: "50px",
            }}
            className="modalbutton mr-auto "
            variant="secondary"
            onClick={handleClose}
          >
            <img
              style={{
                height: "100%",
                width: "100%",
                backgroundColor: "#64bb96",
              }}
              src={close}
              alt=""
            />
          </Button>
        </div>
        <Contact />
      </Modal>
    </>
  );
}

export default Payout;
