import React from 'react'
import SpecialIcon from './assets/Special.png'
// import VectorLSEnablerIcon from './assets/VectorLSEnabler.png'
import RE from './assets/icons/RESelect.png'
import RENOT from './assets/icons/RPnot.png'
import LSE from './assets/icons/EnablerSelect.png'
import LSENOT from './assets/icons/SEnablerNot.png'
import SASNOT from './assets/icons/SASnot.png'
import SAS from './assets/icons/SASSelect.png'
const techstatsdata = [
    {
        imageSrc: SASNOT,
        SelectedIcon: SAS,
        title: "Special Account Services",
        key: "SpecialAccountServices"
    }, {
        imageSrc: LSENOT,
        SelectedIcon: LSE,
        title: "Lending Ecosystem Enabler",
        key: "LendingEcosystemEnabler",
    }, {
        imageSrc: RENOT,
        SelectedIcon: RE,
        title: "Recurring Payments Infrastructure",
        key: "RecurringPayments"
    }
]
export default techstatsdata