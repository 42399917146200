import React, { useState } from "react";
import "./Home.scss";
import transbnklogo from "./assets/homepic.png";
import herobannerimg from "./assets/bannerbg23.png";
import techstatsdata from "./techstatdata";
import LendingEcosystem from "./LendingEcosytem";
// import SupplyChain from "./SpecialAccountServices";
import CustodialRecon from "./CustodialRecon";
import MarketplaceSolution from "./MarketplaceSolution";
// import Home5 from "./Home5";
import { Tabs, Tab } from "react-bootstrap";
import SpecialAccountServices from "./SpecialAccountServices";
import RecPay from "./RecPay";
import Contact from "./Contact";
import Marquee from "react-fast-marquee";
import { useHref, useNavigate } from "react-router-dom";
import Features from "./Features";
import Clients from "./Clients";
import signzy from "./assets/Logo/signzy-logo-black.png";
import XtendValue from "./assets/Logo/XtendValue.png";
import Techstalwarts from "./assets/Logo/Techstalwarts logo.png";
import defen from "./assets/Logo/defen.png";
import NSDLPayments from "./assets/Logo/NSDL Payments.png";
import Logo_Gupshup from "./assets/Logo/Logo_Gupshup.png";
import selp from "./assets/Logo/selp.png";
import Mobileware from "./assets/Logo/Mobileware.png";
import vega from "./assets/Logo/vega.png";
import card91 from "./assets/Logo/card91.png";
import close from "./assets/iclose.svg";
import starxyz from "./assets/Vector (1).svg";
import DocuflowImage from './assets/Docuflow.png';
import AccountValidationImage from './assets/Account Validation.png';
import PayoutsImage from './assets/Payouts.png';
import SupplyChainImage from './assets/Supply Chain.png';
import RiskRadarImage from './assets/Risk Radar.png';
import UPIAutopayImage from './assets/UPI Autopay.png';
import NACHImage from './assets/NACH.png';
import DigitalLedgerImage from './assets/Digital Ledger.png';

import {
  Nav,
  Navbar,
  NavDropdown,
  Col,
  Row,
  Container,
  Dropdown,
  Modal,
  Button,
} from "react-bootstrap";
import Testimonial from "./Testimonial";
import { isAuthenticated } from "./utils/auth";
const Home = () => {
  const products = [
    { image: AccountValidationImage, title: 'Bank', content: 'Banking APIs' },
    { image: SupplyChainImage, title: 'Ancillary', content: 'Lending Ecosystem Enabler APIs' },
    { image: PayoutsImage, title: 'Payments', content: 'Recurring Payment APIs' },
    { image: DocuflowImage, title: 'Docuflow', content: 'Onboarding APIs' },

    // { image: RiskRadarImage, title: 'Risk Radar', content: 'Risk Radar content' },
    // { image: UPIAutopayImage, title: 'UPI Autopay', content: 'UPI Autopay content' },
    // { image: NACHImage, title: 'NACH', content: 'NACH content' },
    // { image: DigitalLedgerImage, title: 'Digital Ledger', content: 'Digital Ledger content' },
  ];

  const navigate = useNavigate();

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",

        }}
      >
        <div className="home2" style={{
          marginTop: "10vh",
          height: '80%'

        }}>
          <div className="home1left">
            <div className="contentwraphomeleft">
              {/* <div className="h2tbtitle">
                <img
                  src={starxyz}
                  alt="transbnk logo"
                  className="tabicon2"
                />
                &nbsp; Powering Seamless Integration and Innovation
              </div> */}
              <p className="headingpage2">
                Welcome to TransBnk API Hub
              </p>

              <p className="contentpage2">
                {/* Explore the power of seamless integration with our cutting-edge APIs. From document management and banking solutions to recurring payments and versatile ancillary services, TransBnk API Hub is your gateway to efficiency. Simplify your business operations, enhance financial management, and boost productivity with our comprehensive suite of APIs. Whether you're a developer, business owner, or IT professional, discover the possibilities and unlock the potential of your applications. Experience the future of API-driven innovation right here at the TransBnk API Hub. */}
                Explore the power of seamless integrations with key Lending & Banking Infrastructure using our cutting-edge APIs. From powering the lending ecosystem with Transaction Banking solutions like Escrows to Recurring Payments and Digital Debt collection services, TransBnk API Hub is your gateway to efficiency. Simplify your business operations, enhance financial management, and boost productivity with our comprehensive suite of APIs. Whether you're a developer, business owner, or IT professional, discover the possibilities and unlock the potential of your applications. Experience the future of API-driven innovation right here at the TransBnk API Hub.
              </p>
              <br />
              <p
                style={{ fontWeight: "800", textDecoration: "NONE" }}
                className="contentpage2"
              >
                {!isAuthenticated() ? <a href="/login" className="prodbuttonx" >
                  Sign In
                </a> : <></>}
              </p>
            </div>
          </div>
          {/* <div className="home1right"> */}
          <img
            src={transbnklogo}
            alt="transbnk logo"
            // className="home1rightimg"
            className="home1right"
          />
          {/* </div> */}
        </div>
        {/* <h4
          style={{
            marginTop: "0%",
            fontSize: "1rem",
            marginBottom: "0rem",
            padding: "0%",
            textAlign: "center",
            alignSelf: "center",
            fontWeight: "900",
            fontFamily: "Manrope",
            width: "80%",
            color: "black",
            textTransform: 'uppercase',
            display: "none"
          }}
        >
          Products
        </h4> */}
        <h4
          style={{
            fontSize: "1.89rem",
            marginBottom: "0rem",
            padding: "0 .5%",
            textAlign: "center",
            alignSelf: "center",
            fontWeight: "600",
            fontFamily: "Manrope",
            width: "80%",
            maxWidth: "100%",
            color: "black",
            paddingBottom: 10,
          }}
        >
          Dive deep into our API stack
        </h4>
        <div className="prodcards-container2">
          {products.map((product, index) => (
            <div key={index} className="AHproductcard" onClick={() => {
              navigate("/api-hub/" + product.title); window.scrollTo(0, 0);

            }}>
              <img className='AHimg' src={product.image} alt={`${product.title} Image`} />
              <h2 className="AHtitle">{product.content}</h2>
            </div>
          ))}
        </div >
      </div >

    </>
  );
};

export default Home;
