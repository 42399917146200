import React, { useState } from "react";
import {
  Nav,
  Navbar,
  NavDropdown,
  Col,
  Row,
  Container,
  Dropdown,
  Modal,
  Button,
} from "react-bootstrap";
import close from "../assets/iclose.svg";
import img1 from "./Marketplace.png";
import img2 from "./TrustHub3.png";
import img3 from "../assets/Special.png";
import SESlogo from "../assets/Recurring.png";

import "./Product.scss";
import Contact from "../Contact";
function Autopay() {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  return (
    <>
      <div className="product">
        <div className="product1">
          <div className="product1left">
            <p className="headingproduct">UPI Autopay</p>
            <p className="contentproduct">
              UPI Autopay is a revolutionary payment system in India that
              enables secure, and convenient mandate management and fund
              transfers from end user's bank accounts using smartphones through
              UPI ecosystem.
            </p>
            <div style={{
              display: 'flex',
              flexDirection: "row",
              width: '100%'
            }}>
              <button
                eventKey="13"
                style={{ cursor: "pointer", marginRight: "50px" }}
                onClick={handleShow}
                data-toggle="modal"
                className="prodbuttonx"
              >
                Try out APIs
              </button>
              <button
                eventKey="13"
                style={{ cursor: "pointer" }}
                onClick={handleShow}
                data-toggle="modal"
                className="prodbuttonx2"
              >
                Download PDF
              </button></div>
          </div>
          <div className="product1right">
            <svg
              viewBox="0 0 79 79"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="product1rightimg"
            >
              <circle
                cx="39.6659"
                cy="39.6008"
                r="38.927"
                fill="#66BB94"
                fill-opacity="0.15"
              />
              <path
                d="M45.1422 14L57.9671 39.5075L31.0073 65L45.1422 14Z"
                fill="#22914F"
              />
              <path
                d="M36.1523 14L48.9674 39.5075L22 65L36.1523 14Z"
                fill="#F47D35"
              />
            </svg>
          </div>
        </div>
        <div className="product2">
          <div className="product2left">
            <div className="contentwrapproductleftx">
              <p className="heading2product">
                UPI Autopay offers a seamless and real-time transaction
                experience, allowing users through their mobile devices.
              </p>
              <p className="content2product">
                With UPI Autopay platform of Transbnk, Corporate can control
                mandate management and transactions with ease, all within a
                single platform.
              </p>
            </div>
          </div>
          <div className="product2right">
            <svg
              viewBox="0 0 79 79"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="product2rightimg"
            >
              <circle
                cx="39.6659"
                cy="39.6008"
                r="38.927"
                fill="#66BB94"
                fill-opacity="0.15"
              />
              <path
                d="M45.1422 14L57.9671 39.5075L31.0073 65L45.1422 14Z"
                fill="#22914F"
              />
              <path
                d="M36.1523 14L48.9674 39.5075L22 65L36.1523 14Z"
                fill="#F47D35"
              />
            </svg>
          </div>
        </div>
        <div className="product3">
          <div className="product3left">
            <div className="contentwrapproductleft">
              <p className="heading3product">
                Key Features of TransBnk UPI Autopay{" "}
              </p>
              <ul>
                <ol>
                  <svg
                    width="20"
                    height="15"
                    viewBox="0 0 20 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M19.4648 2.01664L6.71392 14.7675L0.498047 8.55164L2.03813 7.01156L6.71392 11.6873L17.9247 0.476562L19.4648 2.01664Z"
                      fill="#1D6AE5"
                    />
                  </svg>
                  &nbsp; Dashboard access for corporate
                </ol>
                <ol>
                  <svg
                    width="20"
                    height="15"
                    viewBox="0 0 20 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M19.4648 2.01664L6.71392 14.7675L0.498047 8.55164L2.03813 7.01156L6.71392 11.6873L17.9247 0.476562L19.4648 2.01664Z"
                      fill="#1D6AE5"
                    />
                  </svg>
                  &nbsp; Multi Bank Model
                </ol>
                <ol>
                  <svg
                    width="20"
                    height="15"
                    viewBox="0 0 20 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M19.4648 2.01664L6.71392 14.7675L0.498047 8.55164L2.03813 7.01156L6.71392 11.6873L17.9247 0.476562L19.4648 2.01664Z"
                      fill="#1D6AE5"
                    />
                  </svg>
                  &nbsp; Recon Engine for fund reconciliation
                </ol>
                <ol>
                  <svg
                    width="20"
                    height="15"
                    viewBox="0 0 20 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M19.4648 2.01664L6.71392 14.7675L0.498047 8.55164L2.03813 7.01156L6.71392 11.6873L17.9247 0.476562L19.4648 2.01664Z"
                      fill="#1D6AE5"
                    />
                  </svg>
                  &nbsp; Multi module platform with single view access
                </ol>
              </ul>
            </div>
          </div>
          <div className="product3right">
            <img
              src={SESlogo}
              alt="transbnk logo"
              className="product3rightimg"
            />
          </div>
        </div>
      </div>
      <Modal
        className="navmodal"
        show={show}
        backdrop="static"
        onHide={handleClose}
        size="lg"
        centered
      >
        {/* <Modal.Header closeButton><ModalTitle>test</ModalTitle></Modal.Header> */}
        <div class="d-flex justify-content-end">
          <Button
            style={{
              backgroundColor: "#64bb96",
              borderRadius: "50%",
              height: "50px",
              width: "50px",
            }}
            className="modalbutton mr-auto "
            variant="secondary"
            onClick={handleClose}
          >
            <img
              style={{
                height: "100%",
                width: "100%",
                backgroundColor: "#64bb96",
              }}
              src={close}
              alt=""
            />
          </Button>
        </div>
        <Contact />
      </Modal>
    </>
  );
}

export default Autopay;
