import React, { useState } from "react";
import {
  Nav,
  Navbar,
  NavDropdown,
  Col,
  Row,
  Container,
  Dropdown,
  Modal,
  Button,
} from "react-bootstrap";
import close from "../assets/iclose.svg";
import img1 from "./SCF.png";
import img2 from "./SCF2.png";
import img3 from "../assets/Lending.png";
import "./Product.scss";
import Contact from "../Contact";
function SCF() {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  return (
    <>
      <div className="product">
        <div className="product1">
          <div className="product1left">
            <p className="headingproduct">SCF Recon Platform</p>
            <p className="contentproduct">
              A digital ledger manager that offers a plug-and-play multi-bank
              model and invoice reconciliation capabilities, empowering
              businesses in supply chain finance platforms, SCF lenders and
              Anchors.
            </p>
            <div style={{
              display: 'flex',
              flexDirection: "row",
              width: '100%'
            }}>
              <button
                eventKey="13"
                style={{ cursor: "pointer", marginRight: "50px" }}
                onClick={handleShow}
                data-toggle="modal"
                className="prodbuttonx"
              >
                Try out APIs
              </button>
              <button
                eventKey="13"
                style={{ cursor: "pointer" }}
                onClick={handleShow}
                data-toggle="modal"
                className="prodbuttonx2"
              >
                Download PDF
              </button></div>
          </div>
          <div className="product1right">
            <img src={img1} alt="transbnk logo" className="product1rightimg" />
          </div>
        </div>
        <div className="product2">
          <div className="product2left">
            <div className="contentwrapproductleftx">
              <p className="heading2product">
                SCF Recon simplifies supply chain finance by providing a digital
                ledger manager with plug-and-play multi-bank integration and
                advanced invoice reconciliation capabilities.
              </p>
              <p className="content2product">
                Create a supply chain finance Recon Hub with SCF Recon,
                connecting financial ecosystem participants on a secure platform
                while maintaining data privacy and enabling efficient lending
                channels.
              </p>
            </div>
          </div>
          <div className="product2right">
            <img src={img2} alt="transbnk logo" className="product2rightimg" />
          </div>
        </div>
        <div className="product3">
          <div className="product3left">
            <div className="contentwrapproductleft">
              <p className="heading3product">Key Features of SCF Recon</p>
              <ul>
                <ol>
                  <svg
                    width="20"
                    height="15"
                    viewBox="0 0 20 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M19.4648 2.01664L6.71392 14.7675L0.498047 8.55164L2.03813 7.01156L6.71392 11.6873L17.9247 0.476562L19.4648 2.01664Z"
                      fill="#1D6AE5"
                    />
                  </svg>
                  &nbsp; Digital Ledger Manager
                </ol>
                <ol>
                  <svg
                    width="20"
                    height="15"
                    viewBox="0 0 20 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M19.4648 2.01664L6.71392 14.7675L0.498047 8.55164L2.03813 7.01156L6.71392 11.6873L17.9247 0.476562L19.4648 2.01664Z"
                      fill="#1D6AE5"
                    />
                  </svg>
                  Plug-and-Play Multi-Bank Model
                </ol>
                <ol>
                  <svg
                    width="20"
                    height="15"
                    viewBox="0 0 20 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M19.4648 2.01664L6.71392 14.7675L0.498047 8.55164L2.03813 7.01156L6.71392 11.6873L17.9247 0.476562L19.4648 2.01664Z"
                      fill="#1D6AE5"
                    />
                  </svg>
                  &nbsp; Invoice Reconciliation Capabilities
                </ol>
                <ol>
                  <svg
                    width="20"
                    height="15"
                    viewBox="0 0 20 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M19.4648 2.01664L6.71392 14.7675L0.498047 8.55164L2.03813 7.01156L6.71392 11.6873L17.9247 0.476562L19.4648 2.01664Z"
                      fill="#1D6AE5"
                    />
                  </svg>
                  &nbsp; Efficient SAAS tool for managing recon for SCF
                  ecosystem.
                </ol>
              </ul>
            </div>
          </div>
          <div className="product3right">
            <img src={img3} alt="transbnk logo" className="product3rightimg" />
          </div>
        </div>

      </div>
      <Modal
        className="navmodal"
        show={show}
        backdrop="static"
        onHide={handleClose}
        size="lg"
        centered
      >
        {/* <Modal.Header closeButton><ModalTitle>test</ModalTitle></Modal.Header> */}
        <div class="d-flex justify-content-end">
          <Button
            style={{
              backgroundColor: "#64bb96",
              borderRadius: "50%",
              height: "50px",
              width: "50px",
            }}
            className="modalbutton mr-auto "
            variant="secondary"
            onClick={handleClose}
          >
            <img
              style={{
                height: "100%",
                width: "100%",
                backgroundColor: "#64bb96",
              }}
              src={close}
              alt=""
            />
          </Button>
        </div>
        <Contact />
      </Modal>
    </>
  );
}

export default SCF;
