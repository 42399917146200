import React from 'react'
import './Testimonial.scss'
import adi from './assets/ad.png'
import baj from './assets/baj.png'
import muf from './assets/muf.png'
import Carousel from 'react-bootstrap/Carousel';
const testimonialData = [
  {
    photo: adi,
    text:
      'Escrows are key components for lending ecosystem & we were looking for the right technology partners to address various operational challenges in Escrow transactions. Federal Bank and TransBnk have been able to not only reduce the overall onboarding TAT but also bring more transparency, security & efficiency to this process thereby improving overall customer experience.',
    author: 'Team Aditya Birla Finance Ltd.',
  }, {
    photo: muf,
    text:
      'At HML, we have been consistently working to ensure our customers get the best experience of their journey with us along with benefits derived from digital processing. This partnership with TransBnk shall bring in quality delivery and we’re focused on scaling up. We look forward to building an organization with consistent innovation in every aspect of our business. Always keeping our customer needs ahead of everything, TransBnk will continue to keep developing more interesting features in the future.',
    author: 'Kapil Garg, Founder and Managing Director, Mufin Group, Hindon Mercantile Ltd',
  },

  {
    photo: baj,
    text:
      'The collaboration with TransBnk has also led to a high service deliverance rate in Transaction Banking products, mainly in Escrows. With our partnership, we will be able to further enhance the customer experience and offer Escrow & B2B Payment options with faster Go-to-market. Going forward we plan to add more services to digitize Transaction Banking through TransBnk. We wish TransBnk all the very best and look forward to a mutually beneficial partnership.',
    author: 'Tanaji Khot, National Head - Banking Relationships, Repayments, Projects & Transaction Banking, Bajaj Finance Ltd',
  },
];

function Testimonial() {
  return (
    <div class="mainx">
      <br />
      <div class="testimonials">
        <Carousel interval="5000" pause="false">
          {testimonialData.map((testimonial, index) => (
            <Carousel.Item>
              <div className="testimonial" key={index}>
                <img src={testimonial.photo} alt="" className="testimonial-photo" />
                {/* <br />
                <div className="testimonial-text">
                  <p>{testimonial.text}</p>
                  <div className="testimonial-author">{testimonial.author}</div>
                </div> */}
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </div>
  )
}

export default Testimonial