import React from "react";
import "./Privacy.scss";
const Privacy = () => (
  <div className="privacy" id="policy">
    <div className="privacy-content">
      <br />
      <br />
      <br />
      <h2 className="about_text">
        Privacy Policy and Terms of Submitting Personal Data
      </h2>

      <p>
        <strong>INTRODUCTION</strong>
      </p>
      <p>
        We at TRANSBNK Solutions Pvt. Ltd (TRANSBNK) are committed to protecting
        the privacy and security of its users (&ldquo;user(s)&rdquo;,
        &ldquo;your&rdquo;, or &ldquo;you&rdquo;). User and their customers
        privacy is important and maintaining User trust is paramount to us. This
        Privacy Policy (&ldquo;Privacy Policy&rdquo;) explains how we collect,
        use, process, and disclose, User or their customers information.
        &nbsp;By using our website/ app/ platform and affiliated services, User
        consent on their and their customers behalf to the terms of our privacy
        policy (&ldquo;Privacy Policy&rdquo;) in addition to our &lsquo;Terms of
        Services.&rsquo;&nbsp;
      </p>
      <p>
        <strong>General Definitions</strong>
      </p>
      <p>
        &ldquo;Act&rdquo;&nbsp;shall mean the Information Technology Act, 2005,
        and Rules thereunder as amended from
        <br />
        time to time and the Consumer Protection (E-Commerce) Rules, 2020.
      </p>
      <p>
        &ldquo;Information&rdquo;&nbsp;shall mean and include Personal
        Information and Sensitive Personal Data and
        <br />
        Information as may be collected by TRANSBNK.
      </p>
      <p>
        &ldquo;Personal Information (PI)&rdquo;&nbsp;shall have the same meaning
        as under Rule 2 (i) of the Information Technology (Reasonable security
        practices and procedures and sensitive personal data or information)
        Rules, 2011 as amended from time to time. For ease of reference Rule 2
        (i) of the Information Technology (Reasonable security practices and
        procedures and sensitive personal data or information) Rules, 2011 is
        re-produced under Schedule 1.
      </p>
      <p>
        &ldquo;Rules&rdquo;&nbsp;shall mean the Information Technology
        (Reasonable security practices and procedures
        <br />
        and sensitive personal data or information) Rules, 2011 as amended from
        time to time.
        <br />
        &ldquo;Registered User&rdquo;&nbsp;shall mean such user whose
        registration is accepted by TRANSBNK.
        <br />
        &ldquo;Sensitive Personal Data and Information (SPDI)&rdquo;&nbsp;shall
        mean and include information under
        <br />
        Rule 3 of the Information Technology (Reasonable security practices and
        procedures and
        <br />
        sensitive personal data or information) Rules, 2011 as amended from time
        to time. For ease of
        <br />
        reference Rule 3 of the Information Technology (Reasonable security
        practices and procedures
        <br />
        and sensitive personal data or information) Rules, 2011 is re-produced
        under Schedule 1.
      </p>
      <p>
        &ldquo;Services&rdquo;&nbsp;for the purpose of this Privacy Policy shall
        mean any person who by way of
        <br />
        registration has sought or in any manner has requested the services of
        TRANSBNK.
        <br />
        All words and expressions used and not defined in this document but
        defined in the Act or the
        <br />
        Rules shall have the meanings respectively assigned to them in the Act
        or the rules.
      </p>
      <p>
        &quot;User&quot;&nbsp;means the individual accessing or using the
        Service, or the company, or other legal entity on behalf of which such
        individual is accessing or using the Service, as applicable.
      </p>
      <p>
        We encourage User to read this privacy policy to understand the
        collection, use, and disclosure information from time to time, to keep
        Users updated with the changes and updates that we make to this policy.
      </p>
      <p>
        <strong>WHAT INFORMATION DO WE COLLECT?</strong>
      </p>
      <p>
        Users may use TRANSBNK&rsquo;s website to access Information, learn
        about its products and services, read publications and check career
        opportunities etc. without providing any PI/SPDI. However when a User
        agrees to use our services then they will have to share their or their
        customers information with us.&nbsp;
      </p>
      <p>
        TRANSBNK may collect and process PI/ SPDI provided by User in the
        following forms:
      </p>
      <p>
        <br />
        (a) Should User opt to access such services of TRANSBNK, which are
        available only to
        <br />
        Registered Users, certain Information is required to be provided by User
        at registration
        <br />
        as specified below. Providing additional information beyond what is
        required at the time of
        <br />
        registration is entirely optional and can be altered or removed by User
        at any time.
        <br />
        Registered Users are given access to the web portal/business
        applications based on
        <br />
        the password-based authentication which is chosen by them and securely
        stored in
        <br />
        TRANSBNK servers for the purpose of authentication of the Registered
        Users at the time
        <br />
        of login;
      </p>
      <p>
        (b) Information that User provide directly to TRANSBNK via email or
        electronic ommunication;&nbsp;
      </p>
      <p>
        (c) Information that User provide to TRANSBNK through any mode/medium
        include telephone. TRANSBNK may make and keep a record of such
        information shared by User;&nbsp;
      </p>
      <p>
        (d) Information that TRANSBNK has access to from third parties on behalf
        of the User so that they can provide services;
      </p>
      <p>
        (e) Information that User provide to TRANSBNK in physical form whether
        sent through post or courier or handed over to a TRANSBNK representative
        in person; and
      </p>
      <p>
        (f) PI/SPDI collected by TRANSBNK from its employees, suppliers or
        onsite consultants for
        <br />
        the purpose of employment, availing their services and recording their
        attendance
        <br />
        etc.
      </p>
      <p>
        (g) PI/SPDI collected by TRANSBNK from its partners for the purpose of
        providing services to its Registered Users or enabling services for a
        set of customers.&nbsp;
      </p>
      <p>
        TRANSBNK will try to minimize the PI/SPDI collected for a User. However,
        if PI/SPDI has been shared with TRANSBNK and certain services are
        availed basis that PI/SPDI than the same cannot be removed from the
        platform.&nbsp;
      </p>
      <p>
        <strong>This Privacy Policy applies to:</strong>
      </p>
      <p>
        Information that User may provide to us voluntarily as mentioned above
        for using our Services, including but not limited to, full name, mobile
        number, email address, password, photograph, audio file, location, date
        of birth, gender, Permanent Account Number (PAN), Aadhar details, e-KYC
        through UIDAI, CKYC through CERSAI, income/salary information, marital
        status, sexual orientation, biometric data, health status and nominee
        details. We may also hold financial information, including but not
        limited to, billing address, bank account details, bank statements, bank
        transaction details, credit/debit card details, payment related details,
        payment method data, debit instructions, e-mandates, standing
        instructions or any other personal/financial information as required by
        various applicable laws and regulations from time to time to process the
        Services (&ldquo;PI/SPDI&rdquo;).
      </p>
      <p>
        Certain additional information about User or their customers on a
        case-to-case basis. Further, when User visit our Platform, we may log
        certain non-personal information such as their IP address, cookie
        information, browser type, mobile operating system, manufacturer and
        model of their mobile device, geolocation, preferred language, access
        time and time spent.&nbsp;
      </p>
      <p>
        Any information that is freely available, accessible in the public
        domain, furnished under the Right to Information Act, 2005 or any other
        law for the time being in forced, where such information shall not be
        regarded as sensitive personal data or information. We may also retrieve
        from records available with third party Know Your Customer (KYC)
        Registration Agency (KRA), information such as KYC details, KYC status,
        father&rsquo;s name, occupation, address details and related documents.
        We may also verify KYC information from third party resources to
        ascertain that the information provided by User is correct.&nbsp;
      </p>
      <p>
        Please note that our Platform may link to third-party websites/apps
        (&ldquo;Third-Party Sites&rdquo;), that may collect User or their
        customers personal information, for which the Company is not in any
        manner responsible for the security of such Third-Party Sites or their
        privacy practices or content. Nonetheless, we seek to protect the
        integrity of the App and our Platform and welcome any feedback about
        these websites.&nbsp;
      </p>
      <p>
        <strong>HOW DO WE USE INFORMATION?</strong>
      </p>
      <p>We use the PI/SPDI provided by User:</p>
      <ul>
        <li>
          <p>
            To create User or their customers account, verifying User or their
            customers identity and process User or their customers
            transaction(s) on the Platform;
          </p>
        </li>
        <li>
          <p>
            To share with Banks, NBFCs and other partners to enable them to
            provide services.
          </p>
        </li>
        <li>
          <p>
            To assist Banks, NBFCs and other partners to complete KYC
            requirements, generate necessary agreements, generate necessary
            documents, and verify any specific information.&nbsp;
          </p>
        </li>
        <li>
          <p>
            To provide User or their customers Advisory Services on the
            Platform;
          </p>
        </li>
        <li>
          <p>
            To collect statistics about User or their customers usage of the
            Platform and its effectiveness;
          </p>
        </li>
        <li>
          <p>
            To improve and personalize User or their customers experience of the
            Services, as well as customize our interactions with User or their
            customers to further expand the scope of the Platform;
          </p>
        </li>
        <li>
          <p>
            To resolve disputes, provide customer support and troubleshoot
            problems that arise from using our Platform;
          </p>
        </li>
        <li>
          <p>
            To help promote a safe service on the Platform and protect the
            security and integrity of the Platform, the Services and the users;
          </p>
        </li>
        <li>
          <p>
            To collect the applicable fees from User or their customers in
            relation to the Services;
          </p>
        </li>
        <li>
          <p>
            To inform User or their customers about online and offline offers,
            campaigns, programs, products, services and/or updates;
          </p>
        </li>
        <li>
          <p>
            To customize User or their customers experience on the Platform or
            share custom marketing material with User or their customers;
          </p>
        </li>
        <li>
          <p>
            To detect, prevent and protect the Company from any errors, frauds,
            and other criminal or prohibited activity on the Platform;
          </p>
        </li>
        <li>
          <p>
            To enforce and inform User or their customers about our Terms of
            Service;
          </p>
        </li>
        <li>
          <p>
            To process and fulfil User or their customers request for Services
            or respond to User or their customers queries on the Platform;
          </p>
        </li>
        <li>
          <p>To contact User or their customers;</p>
        </li>
        <li>
          <p>
            To allow our business partners and/or affiliated entities to present
            customized messages to User or their customers;
          </p>
        </li>
        <li>
          <p>
            To communicate important notices or changes in the Services offered
            by the Company, use of the Platform, and the terms/policies which
            govern the relationship between User or their customers and the
            Company and with our affiliates; and
          </p>
        </li>
        <li>
          <p>
            For any other purpose, for which User or their customers have
            granted access to such information to us, based on User or their
            customers interaction with Platform.
          </p>
        </li>
      </ul>
      <p>
        We may send User or their customers text messages on User or their
        customers registered mobile number that User or their customers share
        with us.&nbsp;
      </p>
      <p>
        <strong>DISCLOSURE OF INFORMATION?</strong>
      </p>
      <p>
        We may disclose User or their customers PI/SPDI, to the extent
        necessary:
      </p>
      <ul>
        <li>
          <p>
            To statutory and regulatory authorities to comply with applicable
            laws and to respond to lawful requests and due legal process;
          </p>
        </li>
        <li>
          <p>
            To protect the rights, property and safety of the Company, our
            business and services partners and the users, including to enforce
            the Terms of Service;
          </p>
        </li>
        <li>
          <p>
            To share with Banks, NBFCs and other partners to enable them to
            provide services.
          </p>
        </li>
        <li>
          <p>
            To assist Banks, NBFCs and other partners to complete KYC
            requirements, generate necessary agreements, generate necessary
            documents and verify any specific information. To our subsidiaries
            and affiliates, in the ordinary course of business;
          </p>
        </li>
        <li>
          <p>To third-party websites;</p>
        </li>
        <li>
          <p>
            To third-party service partners, including business partners,
            affiliates, service providers, authorized payment gateways,
            collecting banks, KRAs, credit bureaus, etc. for the purpose of
            billing and collection, providing content, products, customer
            support etc.;
          </p>
        </li>
        <li>
          <p>
            In case of a corporate sale, merger, acquisition, sale of business,
            reorganization, dissolution, insolvency or such similar events;
          </p>
        </li>
        <li>
          <p>
            To third parties to market their
            products/services/campaigns/programs, subject to whether users have
            either consented or not opted out of these disclosures;
          </p>
        </li>
        <li>
          <p>
            To fulfil the purpose for which the user provided such information;
          </p>
        </li>
        <li>
          <p>For any other purpose for which the User provided it;</p>
        </li>
      </ul>
      <p>
        <strong>HOW DO WE RETAIN INFORMATION?</strong>
      </p>
      <p>
        We may store and retain User or their customers PI/SPDI, to the extent
        necessary:
      </p>
      <p>
        To carry out the Services for the user/affiliates/partners and others on
        the Platform.;
      </p>
      <p>
        Retain and use the information collected as necessary to comply with our
        legal obligations, resolve disputes or for other business purposes;
      </p>
      <p>
        Retain PI/SPDI for 60 months after User or their customer cancel/
        deactivate/ unsubscribe User or their customers account with us, as our
        business practice; and
      </p>
      <p>
        Where we have no continuing legitimate business need to process User or
        their customers PI/SPDI, we will securely store User or their customers
        personal data and isolate it from any further processing until deletion
        is possible.
      </p>
      <p>
        <strong>HOW DO WE PROTECT THE INFORMATION WE COLLECT?</strong>
      </p>
      <p>
        We are dedicated to guarding the security of User or their customers
        information and our Platform has several industry-standard security
        technologies and procedures devised to help protect User or their
        customers data from unauthorized access, use, destruction or disclosure,
        including technical and organizational security measures to protect the
        security of User or their customers personal data both online and
        offline. The App receives regular security scans and penetration tests.
        In addition, we actively prevent third parties from getting access to
        the personal information of Users that we store and/or process on the
        servers. We will implement reasonable security measures every time User
        or their customers (a) enter, submit, or access User or their customers
        information, (b) register, or (c) access the App.
      </p>
      <p>
        <strong>HOW DO USER OR THEIR CUSTOMERS UPDATE INFORMATION?</strong>
      </p>
      <p>
        User specifically consent and agree that the Personal Information
        pertaining to User or their customers account on the Platform shall be
        both accurate and current. As per the existing legal regulations,
        specific data fields (such as name, Aadhar number, address etc.) in User
        or their customers Personal Information would be locked in our systems
        once User or their customers enter that information in our
        Platform.&nbsp;
      </p>
      <p>
        We will respond to all requests in accordance with the applicable laws.
      </p>
      <p>
        <strong>HOW DO USER DELETE/DEACTIVATE ACCOUNT?</strong>
      </p>
      <p>
        TRANSBNK does not sell, trade, or otherwise transfer to outside third
        parties any Personal Information. Please note that (i) if we delete User
        or their customers Personal Information as requested, we will no longer
        be able to provide our Services to User or their customers and (ii) we
        may need to keep such Personal Information for a while during the
        shutting down and billing process. Users also have the option to request
        for an account deactivation instead. This will temporarily block user
        access to the App and the Platform until they send a re-activation
        request and successfully re-activate their account. Kindly note that
        deletion of Personal Information will be decided on a case-to-case basis
        as there may be cases owing to certain reasons, we cannot delete any
        account.&nbsp;
      </p>
      <p>
        We may retain&nbsp;User or their customers&rsquo;&nbsp;personal
        information in accordance with appliable laws, for a period no longer
        than is required for the purpose for which it was collected or as
        required under any applicable law. However, we may retain data related
        to&nbsp;User or their customers&nbsp;if we believe it may be necessary
        to prevent fraud or future abuse, to enable TransBnk to exercise its
        legal rights and/or defend against legal claims or if required by law or
        for other legitimate purposes. We may continue to retain&nbsp;User or
        their customers&nbsp;data in anonymized form for analytical and research
        purposes.
      </p>
      <p>
        <strong>WILL WE UPDATE THE PRIVACY POLICY?</strong>
      </p>
      <p>
        We reserve the right, at our sole discretion, to revise, change or
        modify this Privacy Policy from time to time and such modifications
        shall be binding upon User or their customers&rsquo;. Therefore, we urge
        User or their customers to review the Privacy Policy periodically to
        ensure that User or their customers agree with our latest information on
        our privacy practices.&nbsp;
      </p>
      <p>
        <strong>COPYRIGHT POLICY</strong>
      </p>
      <p>
        The contents of this website and the Platform may not be reproduced
        partially or fully, without due permission from TRANSBNK, as per the
        Copyright Act, 1957. If referred to as a part of another publication,
        the source must be appropriately acknowledged. The contents of this
        website cannot be used in any misleading or objectionable context.
      </p>
      <p>
        <strong>GOVERNING LAW</strong>
      </p>
      <p>
        This Privacy Policy shall be governed by the law of the republic of
        India, without respect to its conflicts of laws principles. Each of the
        parties to this Privacy Policy consents to the exclusive jurisdiction
        and venue of the courts located in New - Delhi, India for any actions
        not subject to Dispute Resolution and Arbitration provisions as set
        forth below in (Dispute Resolution).
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>DISPUTE RESOLUTION</strong>
      </p>
      <p>
        Any claim, dispute or difference relating to or arising out of this
        Terms of Service and Platform Privacy Policy shall be referred to the
        arbitration, of a sole arbitrator appointed mutually. The arbitration
        shall be subject to the Arbitration and Conciliation Act, 1996 as may be
        amended from time to time. The seat and venue of arbitration shall be
        New - Delhi, India. The proceedings shall be undertaken in English. The
        arbitration award shall be final and binding on the parties.
      </p>
      <p>
        <strong>PRIVACY QUESTIONS </strong>
      </p>
      <p>
        To ask questions or comment about this Privacy Policy and our privacy
        practices, please contact us at:
      </p>
      <p>
        Email:&nbsp;
        <a href="mailto:admin@transbnk.co.in">admin@transbnk.co.in</a>
      </p>
      <p>
        Address: M-32 (Market), First Floor, Greater Kailash 2, New Delhi -
        110048
      </p>
      <p>
        <br />
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>GRIEVANCE REDRESSAL MECHANISM</strong>
      </p>
      <p>
        In accordance with the Information Technology Act, 2005 and the rules
        made thereunder, i.e.&nbsp;Information Technology (Reasonable Security
        Practices and Procedures and Sensitive Personal Data or Information)
        Rules, 2011&nbsp;and&nbsp;Information Technology (Intermediary
        Guidelines and Digital Media Ethics Code) Rules, 2021&nbsp;the name and
        contact details of the Grievance Officer are provided below.&nbsp;Any
        complaints, abuse or concerns with regards to the use, processing,
        deletion and disclosure of Information provided by User or their
        customers or breach of these terms should immediately be informed to the
        designated Grievance Officers as mentioned below via in writing or
        through email signed&nbsp;
      </p>
      <p>Name: Srishti Agarwal</p>
      <p>
        E-mail:{" "}
        <a href="mailto:Srishti.agarwal@transbnk.co.in">
          Srishti.agarwal@transbnk.co.in
        </a>
      </p>
      <p>
        Address: M-32 (Market), First Floor, Greater Kailash 2, New Delhi -
        110048
      </p>
      <p>Telephone: +91 11-41415558</p>
      <p>
        <br />
      </p>
      <p>
        We request User to please provide the following information in their
        complaint/Grievance: -
      </p>
      <p>
        <br />
        a) Identification of the information provided by User;
      </p>
      <p>
        b) Clear statement as to whether the information is personal information
        or sensitive personal information;
        <br />
        c) User address, telephone number or e-mail address;
        <br />
        d) A statement that User have a good-faith belief that the information
        has been processed incorrectly or disclosed without authorization, as
        the case may be;
        <br />
        e) A statement, under penalty of perjury, that the information in the
        notice is accurate, and that the information being complained about
        belongs to User;
        <br />
        <br />
      </p>
      <p>
        The Company may reach out to User to confirm or discuss certain details
        about Users complaint and issues raised.
        <br />
        <br />
      </p>
      <p>
        The Company shall not be responsible&nbsp;for any communication, if
        addressed, to any non-designated person in this regard.
        <br />
        <br />
      </p>
      <p>
        In accordance with the Consumer Protection (E-Commerce) Rules, 2020, the
        name and contact details of the Customer Care Service and Grievance
        Officer of Transbnk solutions Private Limited are provided below:
      </p>
      <p>
        <br />
      </p>
      <h2>Customer Care Service</h2>
      <p>
        <br />
      </p>
      <p>Phone:&nbsp;+91 11-41415558</p>
      <p>Email: admin@transbnk.co.in</p>
      <p>
        <br />
      </p>
      <p>
        <br />
      </p>
      <h2>Grievance Officer</h2>
      <p>
        <br />
      </p>
      <p>Name: Srishti Agarwal</p>
      <p>
        E-mail:{" "}
        <a href="mailto:Srishti.agarwal@transbnk.co.in">
          Srishti.agarwal@transbnk.co.in
        </a>
      </p>
      <p>
        Address: M-32 (Market), First Floor, Greater Kailash 2, New Delhi -
        110048
      </p>
      <p>Telephone: +91 11-41415558</p>
      <p>
        For any complaints or grievances received, our Grievance Officer shall
        acknowledge the receipt of the consumer complaint&nbsp;within
        forty-eight (48) hours&nbsp;and redress the complaint&nbsp;within one
        (1) month&nbsp;from the date of receipt of the complaint.
      </p>
      <p>
        <br />
      </p>
      <p>
        <br />
      </p>
      <p>
        <br />
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>Schedule 1</strong>
      </p>
      <p>
        <br />
        <strong>
          Rule 2 (i)
          <br />
        </strong>
        &ldquo;Personal information&quot; means any information that relates to
        a natural person, which, either
        <br />
        directly or indirectly, in combination with other information available
        or likely to be available
        <br />
        with a body corporate, is capable of identifying such person.
        <br />
        <strong>
          Rule 3<br />
        </strong>
        Sensitive personal data or information of a person means such personal
        information which
        <br />
        consists of information relating to:-
        <br />
        (i) password;
        <br />
        (ii) financial information such as Bank account or credit card or debit
        card or other payment
        <br />
        instrument details ;<br />
        (iii) physical, physiological and mental health condition;
        <br />
        (iv) sexual orientation;
        <br />
        (v) medical records and history;
        <br />
        (vi) Biometric information;
        <br />
        (vii) any detail relating to the above clauses as provided to body
        corporate for providing service;&nbsp;and
        <br />
        (viii) any of the information received under above clauses by body
        corporate for processing,
        <br />
        stored or processed under lawful contract or otherwise:
        <br />
        <br />
      </p>
      <p>
        provided that, any information that is freely available or accessible in
        public domain or furnished under the Right to Information Act, 2005 or
        any other law for the time being in force shall not be regarded as
        sensitive personal data or information for the purposes of these rules.
      </p>
      <p>
        <br />
        <br />
        <br />
      </p>
    </div>
  </div>
);

export default Privacy;
