import React from "react";
import "./contact.scss";
import transbnklogo from "./assets/TransBnk Logo TM (4).png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faTwitter,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import ReCAPTCHA from "react-google-recaptcha";
import { useRef, useState } from "react";
import Swal from "sweetalert2/dist/sweetalert2.js";

import "sweetalert2/src/sweetalert2.scss";
import emailjs from "emailjs-com";
import { Button, Form, Dropdown } from "react-bootstrap";
const options = [
  "TrustHub",
  "DEBS",
  "Marketplace Escrow",
  "Docuflow",
  "Risk Radar",
  "SCF Recon Platform",
  "Co-Lending Platform",
  "BBPS",
  "UPI Autopay",
  "ENACH",
  "Paysphere",
];

const Contact = () => {
  const [validated, setValidated] = useState(false);
  const [verfied, setVerifed] = useState(false);
  const testSiteKey = process.env.REACT_APP_RECAPTCHA;
  const [formData, setFormData] = useState({
    selectedOptions: [],
  });

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    let updatedOptions = [...formData.selectedOptions];

    if (checked) {
      updatedOptions.push(value);
    } else {
      updatedOptions = updatedOptions.filter((option) => option !== value);
    }

    setFormData({ selectedOptions: updatedOptions });
  };

  //recaptcha function
  function onChange(value) {
    setVerifed(true);
  }
  const formRef = useRef();
  const [done, setDone] = useState(false);

  const handleSubmit = (e) => {
    var optiondata = [...formData.selectedOptions];
    console.log(optiondata);
    console.log(optiondata.length);
    if (optiondata.length > 0) {
      e.preventDefault();
      emailjs
        .sendForm(
          process.env.REACT_APP_EMAILJS_SERVICE,
          process.env.REACT_APP_EMAILJS_TEMPLATE,
          formRef.current,
          process.env.REACT_APP_EMAILJS_KEY
        )
        .then(
          (result) => {
            console.log(result.text);
            Swal.fire({
              title: "Thank you for reaching out. ",
              text: "We appreciate your inquiry and will respond to you in a timely manner.",
              imageUrl: transbnklogo,
              imageWidth: 100,
              imageAlt: "Transbnk logo",
              timer: 1500,
              confirmButtonColor: "#66bb94",
              confirmButtonText: "",
              showConfirmButton: false,
            });
          },
          (error) => {
            console.log(error.text);
          }
        );

      const form = e.currentTarget;
      if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
      }
    } else {
      e.preventDefault();
      e.stopPropagation();
      Swal.fire({
        icon: "info",
        title: "Error",
        text: "Please Select atleast one product",
      });
    }
  };
  return (
    <div className="background">
      <div className="containerContact">
        <div className="screen">
          <div className="screen-header">
            <div className="screen-header-left">
              <div className="screen-header-button close"></div>
              <div className="screen-header-button maximize"></div>
              <div className="screen-header-button minimize"></div>
            </div>
            <div className="screen-header-right">
              <div className="screen-header-ellipsis"></div>
              <div className="screen-header-ellipsis"></div>
              <div className="screen-header-ellipsis"></div>
            </div>
          </div>
          <div className="screen-body">
            <div className="screen-body-item left">
              <div className="app-title">
                <span>CONTACT</span>
                <span>US</span>
              </div>
              <img
                src={transbnklogo}
                alt="transbnk logo"
                className="Contactimg"
                style={{}}
              />
              <br />
              <div className="app-contact">
                <div className="social-icons">
                  <div className="social-iconswrap">
                    <a
                      href="https://www.facebook.com/TransBnk-108030988593818"
                      className="social-icon-link facebook"
                      aria-label="Facebook"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faFacebookF} color="#66BB94" />
                    </a>
                    <a
                      href="https://www.instagram.com/transbnk/"
                      className="social-icon-link youtube"
                      aria-label="Instagram"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faInstagram} color="#66BB94" />
                    </a>
                    <a
                      href="https://twitter.com/TransBnkIndia"
                      className="social-icon-link twitter"
                      aria-label="Twitter"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faTwitter} color="#66BB94" />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/transbnk-transaction-banking-platform"
                      className="social-icon-link twitter"
                      aria-label="LinkedIn"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faLinkedin} color="#66BB94" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="screen-body-item">
              <Form
                ref={formRef}
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
              >
                <Form.Control
                  required
                  className="app-form-control"
                  type="text "
                  placeholder="&nbsp;Full Name"
                  name="from_name"
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  Please enter your name.
                </Form.Control.Feedback>
                <Form.Control
                  className="app-form-control"
                  required
                  type="text"
                  placeholder="&nbsp;Company"
                  name="company"
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  Please enter your company.
                </Form.Control.Feedback>
                <Form.Control
                  className="app-form-control"
                  required
                  type="text"
                  placeholder="&nbsp;Designation"
                  name="designation"
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  Please enter your designation.
                </Form.Control.Feedback>
                <Form.Control
                  className="app-form-control"
                  required
                  type="email"
                  placeholder="&nbsp;Email Address"
                  name="email"
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  Please enter your email.
                </Form.Control.Feedback>
                <Form.Control
                  className="app-form-control"
                  required
                  type="tel"
                  placeholder="&nbsp;Mobile Number"
                  name="mobile"
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  Please enter your mobile number.
                </Form.Control.Feedback>
                <br />
                <Dropdown>
                  <Dropdown.Toggle
                    variant="secondary"
                    className="app-form-dropdown"
                    id="dropdown-checkbox"
                  >
                    Select Products
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="asdropdown">
                    {options.map((option) => (
                      <Form.Check
                        key={option}
                        type="checkbox"
                        label={option}
                        name="options"
                        value={option}
                        checked={formData.selectedOptions.includes(option)}
                        onChange={handleCheckboxChange}
                      />
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                <div style={{ marginBottom: "10px" }}></div>
                <Button
                  disabled={!verfied}
                  type="submit"
                  className="app-form-dropdown "
                  // className="app-form-button"
                  variant={verfied ? "success" : "secondary"}
                >
                  Submit
                </Button>{" "}
                <div style={{ marginBottom: "10px" }}></div>
                {/* {done && <span>We'll get back to you ASAP :)</span>} */}
              </Form>
              {/* <div className="app-form">
                <div className="app-form-group">
                  <input className="app-form-control" placeholder="NAME" />
                </div>
                <div className="app-form-group">
                  <input className="app-form-control" placeholder="EMAIL" />
                </div>
                <div className="app-form-group">
                  <input className="app-form-control" placeholder="CONTACT NO" />
                </div>
                <div className="app-form-group message">
                  <input className="app-form-control" placeholder="MESSAGE" />
                </div>
                <div className="app-form-group buttons">
            
                  <button className="app-form-button">SEND</button>
                </div>
              </div> */}

              <ReCAPTCHA sitekey={testSiteKey} onChange={onChange} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
