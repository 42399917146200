import React, { useState } from "react";
import {
  Nav,
  Navbar,
  NavDropdown,
  Col,
  Row,
  Container,
  Dropdown,
  Modal,
  Button,
} from "react-bootstrap";
import close from "../assets/iclose.svg";
import img3 from "../assets/Lending.png";
import "./Product.scss";
import Contact from "../Contact";
import { useNavigate } from "react-router-dom";
import { isAuthenticated } from "../utils/auth";
import Swal from "sweetalert2";
function Bank() {
  const navigate = useNavigate();


  return (
    <>
      <div className="product">
        <div className="product1">
          <div className="product1left">
            <p className="headingproduct">Banking APIs</p>
            <p className="contentproduct">
              Enhance business operations with our Banking API, providing seamless integration and custom solutions for managing all your banking needs.
            </p>
            <div style={{
              display: 'flex',
              flexDirection: "row",
              width: '100%'
            }}>
              <button
                eventKey="13"
                style={{ cursor: "pointer", marginRight: "50px" }}
                onClick={() => {
                  // navigate("/api/docuflow");
                  isAuthenticated() ?
                    window.open("/api/bank", '_blank')
                    : Swal.fire({
                      position: "top-end",
                      icon: "info",
                      text: "Please Login to Try out the APIs",
                      showConfirmButton: false,
                      timer: 1500
                      , heightAuto: false
                    });;
                }}
                data-toggle="modal"
                className="prodbuttonx"
              >
                Try out APIs
              </button>
              <button
                eventKey="13"
                style={!isAuthenticated() ? { display: "none" } : { cursor: "pointer" }}
                onClick={() => {
                  window.open('https://api-doc.trusthub.in/latest-zips/banking_apis.zip', '_blank'); // '
                }}
                data-toggle="modal"
                className="prodbuttonx2"
              >
                Download PDF
              </button>
            </div>


          </div>
          <div className="product1right">
            <div className="contentwrapproductleft">
              <p className="heading3product">Key Features of Banking APIs</p>
              <ul>
                <ol>
                  <svg
                    width="20"
                    height="15"
                    viewBox="0 0 20 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M19.4648 2.01664L6.71392 14.7675L0.498047 8.55164L2.03813 7.01156L6.71392 11.6873L17.9247 0.476562L19.4648 2.01664Z"
                      fill="#1D6AE5"
                    />
                  </svg>
                  &nbsp; Hassle-free Integration
                </ol>
                <ol>
                  <svg
                    width="20"
                    height="15"
                    viewBox="0 0 20 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M19.4648 2.01664L6.71392 14.7675L0.498047 8.55164L2.03813 7.01156L6.71392 11.6873L17.9247 0.476562L19.4648 2.01664Z"
                      fill="#1D6AE5"
                    />
                  </svg>
                  &nbsp; Automate Payments and Collections
                </ol>
                <ol>
                  <svg
                    width="20"
                    height="15"
                    viewBox="0 0 20 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M19.4648 2.01664L6.71392 14.7675L0.498047 8.55164L2.03813 7.01156L6.71392 11.6873L17.9247 0.476562L19.4648 2.01664Z"
                      fill="#1D6AE5"
                    />
                  </svg>
                  &nbsp; Effortless Reconciliation
                </ol>
                <ol>
                  <svg
                    width="20"
                    height="15"
                    viewBox="0 0 20 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M19.4648 2.01664L6.71392 14.7675L0.498047 8.55164L2.03813 7.01156L6.71392 11.6873L17.9247 0.476562L19.4648 2.01664Z"
                      fill="#1D6AE5"
                    />
                  </svg>
                  &nbsp; Real Time Financial Insights
                </ol>
                <ol>
                  <svg
                    width="20"
                    height="15"
                    viewBox="0 0 20 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >

                  </svg>
                  &nbsp; and more...
                </ol>
              </ul>
            </div>
          </div>
        </div>
        <div className="product2">
          <div className="product2left">
            <div className="contentwrapproductleftx">
              <p className="heading2product">
                Uncomplicate banking services with the Banking API, a dedicated platform offering custom-made APIs for easy execution of banking tasks. Take control of banking reports and operations securely, with accessibility and advantage.
              </p>
              <p className="content2product">
                Eliminate transaction-related headaches with our comprehensive solution, making banking services secure, accessible, and advantageous for your business.
              </p>
            </div>
          </div>
        </div>



      </div>

    </>
  );
}

export default Bank;
