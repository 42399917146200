import React, { useState } from "react";
import "./MegaMenu.scss";
import {
  Nav,
  Navbar,
  NavDropdown,
  Col,
  Row,
  Container,
  Dropdown,
  Modal,
  Button,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import transbnklogo from "./assets/TransBnk Logo TM (4).png";
import close from "./assets/iclose.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Contact from "./Contact";
import { getRefreshToken, getToken, isAuthenticated, removeRefreshToken, removeToken, setRefreshToken } from "./utils/auth";
import authService from "./utils/authService";
const MegaMenux = () => {
  const [show, setShow] = useState(false);

  const navigate = useNavigate();
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const handleLogout = () => {
    removeToken();
    removeRefreshToken();
  };
  const handleTokenRefresh = async () => {
    const refreshToken = getRefreshToken();
    if (!refreshToken) {
      handleLogout();
      return;
    }

    try {
      const newToken = await authService.refreshToken(refreshToken);
      setRefreshToken(newToken);
    } catch (error) {
      console.error('Token refresh failed:', error);
      handleLogout();
    }
  };
  const linkStyle = {
    color: '#333',
    textDecoration: 'none',
    // Add more styles as needed
  };
  const sidebarLink2Style = {
    fontWeight: 'bold',
    padding: 0,
    margin: 0,
    marginTop: 10,
    color: 'black'
    // Add more styles as needed
  };
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="light"
        variant="light"
        className="navcolor"
        fixed="top"
      >
        <Container fluid className="navcolor">
          <Navbar.Brand href="/">
            <img src={transbnklogo} alt="GreattrLogo" width={60} />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav navbs"
            className="ms-auto"
          />

          <Navbar.Collapse
            id="responsive-navbar-nav navcon"
            className="scroll-containerx"
          >
            <Nav className="ms-auto navbs">
              <Nav.Link href="/" style={sidebarLink2Style}>

                <p style={sidebarLink2Style}>TransBnk API Developer Portal</p>
              </Nav.Link>
              <div className="respnav">

                <Nav.Link href="/api-hub/introduction" style={linkStyle}>Introduction</Nav.Link>
                <Nav.Link href="/Register" style={linkStyle}>Login or Register</Nav.Link>
                <p style={sidebarLink2Style}>API Stack</p>
                <Nav.Link href="/api-hub/docuflow" style={linkStyle}>Docuflow</Nav.Link>
                <Nav.Link href="/api-hub/bank" style={linkStyle}>Bank</Nav.Link>
                <Nav.Link href="/api-hub/payments" style={linkStyle}>Payment Mandate</Nav.Link>
                <Nav.Link href="/api-hub/ancillary" style={linkStyle}>Ancillary</Nav.Link>
              </div>
              <br />
            </Nav>
            <Nav>
              {/* <p>{isAuthenticated() + ''}</p> */}
              {
                !isAuthenticated() ? <Nav.Link
                  onClick={() => {
                    navigate("/login");

                  }}
                  className="navbuttonx"
                >
                  Sign In
                </Nav.Link> :
                  <Nav.Link
                    onClick={() => {
                      console.log("logout");
                      removeToken();
                      removeRefreshToken();
                      navigate("/login")
                    }}
                    className="navbuttonx"
                  >
                    Logout
                  </Nav.Link>
              }

              <br />
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Modal
        className="navmodal"
        show={show}
        backdrop="static"
        onHide={handleClose}
        size="lg"
        centered
      >
        {/* <Modal.Header closeButton><ModalTitle>test</ModalTitle></Modal.Header> */}
        <div class="d-flex justify-content-end">
          <Button
            style={{
              backgroundColor: "#64bb96",
              borderRadius: "50%",
              height: "50px",
              width: "50px",
            }}
            className="modalbutton mr-auto "
            variant="secondary"
            onClick={handleClose}
          >
            <img
              style={{
                height: "100%",
                width: "100%",
                backgroundColor: "#64bb96",
              }}
              src={close}
              alt=""
            />
          </Button>
        </div>
        <Contact />
      </Modal>
    </>
  );
};

export default MegaMenux;
