import React from "react";
import "./features.css";
import fgro from "../src/assets/icons/fgro.png";
import ffast from "../src/assets/icons/ffast.png";
import fpro from "../src/assets/icons/fpro.png";
import fleg from "../src/assets/icons/fleg.png";
import fsol from "../src/assets/icons/fsol.png";
import ftrust from "../src/assets/icons/ftrust.png";
import finte from "../src/assets/icons/finte.png";
import fapi from "../src/assets/icons/fapi.png";
const Features = () => {
  return (
    <div id="featuresSection" className="container">
      <div className="row mt-3 mb-3">
        <div className="col-12 text-center">
          <h1 className="mainHeading">
            Features that make Design simply awesome
          </h1>
          <br />
          {/* <p className="subheading">
            Not sure if Design is for you? Check out a few of the key features!
          </p> */}
        </div>
      </div>
      <div className="row">
        <FeatureItem
          imgSrc={finte}
          title="Seamless and Effortless Integration"
          description="Highly configurable architecture ensures fast and hassle-free integration for Corporate offerings."
        />
        <FeatureItem
          imgSrc={fapi}
          title="Comprehensive APIs and SDKs"
          description="A complete range of APIs so that your developers are enabled with robust tools to enhance your offerings."
        />
        <FeatureItem
          imgSrc={ftrust}
          title="Trustworthy & Highly Secure Solution"
          description="Team of Banking experts ensures that our solutions are compliant with legal and regulatory requirements."
        />
        <FeatureItem
          imgSrc={ffast}
          title="Faster and Efficient implementation"
          description="Optimize resources & reduce costs to boost efficiency with productivity."
        />
        <FeatureItem
          imgSrc={fleg}
          title="Regulatory/Legal Compliant"
          description="Team of Banking experts ensures that our solutions are compliant with legal and regulatory requirements."
        />
        <FeatureItem
          imgSrc={fpro}
          title="Transparent and Traceable Processes"
          description="Gain increased visibility into your operations with detailed analysis of every step and offering."
        />
        <FeatureItem
          imgSrc={fgro}
          title="Scalable and Flexible Solutions for Future Growth"
          description="Accommodate your growing business demands with scalable solutions that adapt to your evolving needs."
        />
        <FeatureItem
          imgSrc={fsol}
          title="Solutions Tailored to Your Needs"
          description="Customize our offerings to align perfectly with your unique requirements."
        />
      </div>
    </div>
  );
};

const FeatureItem = ({ imgSrc, title, description }) => {
  return (
    <div className="col-12 col-md-6 mt-3">
      <div className="d-flex flex-row">
        <img src={imgSrc} alt="" style={{ width: "50px" }} />
        <div className="fontfamilyclass">
          <h2 style={{ fontSize: "1.2rem" }}>{title}</h2>
          <p style={{ fontSize: "1rem" }}>{description}</p>
        </div>
      </div>
    </div>
  );
};

export default Features;
