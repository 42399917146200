import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./Layout";
import Home from "./Home";
import About from "./About";
import Product from "./Product";
import CoLending from "./Products/CoLending";
import DEBS from "./Products/DEBS";
import Docuflow from "./Products/Docuflow";
import Market from "./Products/Market";
import Paysphere from "./Products/Paysphere";
import PTrustHub from "./Products/PTrustHub";
import RiskRadar from "./Products/RiskRadar";
import SCF from "./Products/SCF";
import BBPS from "./Products/BBPS";
import Autopay from "./Products/Autopay";
import NACH from "./Products/NACH";
import Contact from "./Contact";
import Faq from "./Faq";
import NewsSection from "./NewsSection";
import NewsSection2 from "./NewsSection2";
import Privacy from "./Privacy/Privacy";
import Terms from "./Privacy/Terms";
import Landing from "./Template/screens/Landing";
import Hero from "./Hero";
import Gallery from "./Gallery";
import Login from "./Login";
import authService from "./utils/authService";
import {
  getToken,
  removeToken,
  getRefreshToken,
  removeRefreshToken,
  setRefreshToken,
  isAuthenticated,
} from "./utils/auth";
import Register from "./Register";
import DocuflowAPI from "./Products/DocuflowApi";
import "./custom_c.scss";
import Bank from "./Products/Bank";
import Payout from "./Products/Payouts";
import Ancillary from "./Products/Ancillary";
import AncillaryAPI from "./Products/AncillaryAPI";
import PayoutsAPI from "./Products/PayoutsAPI";
import BankAPI from "./Products/BankAPI";
import NotLoggedInPage from "./NotLoggedInPage";
const App = () => {
  const [tokenExpired, setTokenExpired] = useState(false);
  const BASE_URL = process.env.API_LINK;

  useEffect(() => {
    // Function to check token expiration
    const checkTokenExpiration = () => {
      try {
        const token = getToken();
        // If no token is present, consider it expired
        if (!token) {
          setTokenExpired(true);
          return;
        }

        // Decode the token to get expiration time
        const decodedToken = authService.decodeToken(token);
        const currentTime = Math.floor(Date.now() / 1000);

        // If the token is expired, set the state to true
        if (decodedToken.exp < currentTime) {
          setTokenExpired(true);
          console.log("expired");
          // removeToken();
          // removeRefreshToken();
          handleTokenRefresh();
        } else {
          // Calculate the remaining time before token expiration
          const timeLeftInSeconds = decodedToken.exp - currentTime;
          console.log(timeLeftInSeconds);
        }
      } catch (error) {
        // If there's an error decoding the token, consider it expired
        console.log("Error decoding token:", error);
        setTokenExpired(true);
      }
    };

    // Call the function to check token expiration
    checkTokenExpiration();
  }, []); //

  const handleLogout = () => {
    removeToken();
    removeRefreshToken();
  };

  const handleTokenRefresh = async () => {
    const refreshToken = getRefreshToken();
    console.log(refreshToken);
    console.log(refreshToken);
    console.log(refreshToken);
    if (!refreshToken) {
      handleLogout();
      return;
    }

    try {
      const newToken = await authService.refreshToken(refreshToken);
      setRefreshToken(newToken);
    } catch (error) {
      console.error("Token refresh failed:", error);
      handleLogout();
    }
  };

  return (
    <Router>
      <Routes>
        {!isAuthenticated() ? (
          <Route path="/api/*" element={<NotLoggedInPage />} />
        ) : (
            <Route
              path="/api/*"
              element={
                <Routes>
                  <Route path="/docuflow" element={<DocuflowAPI />} />
                  <Route path="/bank" element={<BankAPI />} />
                  <Route path="/payments" element={<PayoutsAPI />} />
                  <Route path="/ancillary" element={<AncillaryAPI />} />
                </Routes>
              }
            />
        )}
        <Route path="/Login" element={<Login />} />
        <Route path="/Register" element={<Register />} />
        <Route path="/" element={
        <Layout>
        <Home />
</Layout>
        } />
        <Route
          path="/api-hub/*"
          element={
            <Layout>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/introduction" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/product" element={<Product />} />
                <Route path="/CoLending" element={<CoLending />} />
                <Route path="/DEBS" element={<DEBS />} />
                <Route path="/Docuflow" element={<Docuflow />} />
                <Route path="/Bank" element={<Bank />} />
                <Route path="/Payments" element={<Payout />} />
                <Route path="/Ancillary" element={<Ancillary />} />
                <Route path="/MarketPlaceEscrow" element={<Market />} />
                <Route path="/Paysphere" element={<Paysphere />} />
                <Route path="/TrustHub" element={<PTrustHub />} />
                <Route path="/RiskRadar" element={<RiskRadar />} />
                <Route path="/SCF" element={<SCF />} />
                <Route path="/BBPS" element={<BBPS />} />
                <Route path="/UPI-Autopay" element={<Autopay />} />
                <Route path="/eNACH" element={<NACH />} />

                <Route path="/Contact" element={<Contact />} />
                <Route path="/Faq" element={<Faq />} />
                {/* <Route exact path="/news" element={<Media />} /> */}
                <Route exact path="/news" element={<NewsSection />} />
                <Route
                  exact
                  path="/Fintech-Yatra-2023"
                  element={<NewsSection2 />}
                />
                <Route exact path="/privacy" element={<Privacy />} />
                <Route exact path="/terms" element={<Terms />} />
                <Route exact path="/test" element={<Landing />} />
                <Route exact path="/hero" element={<Hero />} />
                <Route exact path="/awards" element={<Gallery />} />
              </Routes>
            </Layout>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
