import React, { useState } from "react";
import "./LendingEcosystem.scss";

import SESlogo from "./assets/Recurring.png";
import emptyimg from "./assets/feature2.svg.png";
import Lending from "./assets/Marketing.svg";
import techstatsdata from "./techstatdata";
const RecPay = () => {
  return (
    <div className="SES">
      <div className="SES1">
        <img src={SESlogo} alt="transbnk logo" className="SESimg" />
        <div className="SES1contentWrap">
          <h2 className="SES1heading">
            Simplify Your Payments with Recurring Payments Services
          </h2>
          <h2 className="SES1content">
            Streamline your payments with our Recurring Payments Services,
            ensuring timely and hassle-free transactions
          </h2>
        </div>
      </div>

      <div className="SES2">
        <a className="SES2Card" href="/BBPS">
          <svg
            width="55"
            height="55"
            viewBox="0 0 79 79"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="39.6659"
              cy="39.6008"
              r="38.927"
              fill="#66BB94"
              fill-opacity="0.15"
            />
            <path
              d="M45.1422 14L57.9671 39.5075L31.0073 65L45.1422 14Z"
              fill="#22914F"
            />
            <path
              d="M36.1523 14L48.9674 39.5075L22 65L36.1523 14Z"
              fill="#F47D35"
            />
          </svg>

          <div className="SES2ContentWrap">
            <h2 className="SES2heading">BBPS</h2>
            <h2 className="SES2content">
              Simplify bill payments with BBPS, an integrated platform that
              enables users to make convenient and secure payments for various
              services.{" "}
            </h2>
          </div>
        </a>
        <a className="SES2Card" href="/UPI-Autopay">
          <svg
            width="55"
            height="55"
            viewBox="0 0 79 79"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="39.6659"
              cy="39.6008"
              r="38.927"
              fill="#66BB94"
              fill-opacity="0.15"
            />
            <path
              d="M45.1422 14L57.9671 39.5075L31.0073 65L45.1422 14Z"
              fill="#22914F"
            />
            <path
              d="M36.1523 14L48.9674 39.5075L22 65L36.1523 14Z"
              fill="#F47D35"
            />
          </svg>

          <div className="SES2ContentWrap">
            <h2 className="SES2heading">UPI Autopay</h2>
            <h2 className="SES2content">
              UPI is a revolutionary payment system in India that enables
              instant, secure, and convenient fund transfers between bank
              accounts using smartphones.{" "}
            </h2>{" "}
          </div>
        </a>
        <a className="SES2Card" href="/eNACH">
          <svg
            width="55"
            height="55"
            viewBox="0 0 79 79"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="39.6659"
              cy="39.6008"
              r="38.927"
              fill="#66BB94"
              fill-opacity="0.15"
            />
            <path
              d="M45.1422 14L57.9671 39.5075L31.0073 65L45.1422 14Z"
              fill="#22914F"
            />
            <path
              d="M36.1523 14L48.9674 39.5075L22 65L36.1523 14Z"
              fill="#F47D35"
            />
          </svg>

          <div className="SES2ContentWrap">
            <h2 className="SES2heading">NACH</h2>
            <h2 className="SES2content">
              NACH Mandate Management is a comprehensive solution that
              simplifies and automates the management of mandates{" "}
            </h2>
          </div>
        </a>
      </div>
    </div>
  );
};

export default RecPay;
