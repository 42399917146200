// Layout.js
import React from 'react';
import { Route, useLocation } from 'react-router-dom';
import Sidebar from './Sidebar';
import MegaMenux from './MegaMenu';

const Layout = ({ children }) => {
  const location = useLocation();
  return (
    <div>
      {!location.pathname.match('login') && !location.pathname.match('register') && <MegaMenux />}
      <div className="gokukillsjiren">
        {!location.pathname.match('login') && !location.pathname.match('register') && location.pathname !== ('/') && <Sidebar />}
        <div className={"contentsidebar"}>
          {/* Main content */}
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;
