import React, { useState } from "react";
import {
  Nav,
  Navbar,
  NavDropdown,
  Col,
  Row,
  Container,
  Dropdown,
  Modal,
  Button,
} from "react-bootstrap";
import close from "../assets/iclose.svg";
import img1 from "./Docuflow.png";
import img2 from "./Docuflow2.png";
import img3 from "../assets/Lending.png";
import "./Product.scss";
import Contact from "../Contact";
import { useNavigate } from "react-router-dom";
// import test from "../../public
function DocuflowAPI() {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <iframe
      title="Docuflow"
      src={"/docuflow-apis/index.html"}
      // src={"/test/index.html"}
      id="widget"
      name="widget"
      scrolling="auto"
      frameBorder="0"
      width="100%"
      style={{ position: "relative" }}
    ></iframe>
  );
}

export default DocuflowAPI;
