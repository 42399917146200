// utils/auth.js
const TOKEN_KEY = 'your_app_token';
const REFRESH_TOKEN_KEY = 'your_app_refresh_token';

const setToken = (token) => {
  localStorage.setItem(TOKEN_KEY, token);
};

const setRefreshToken = (refreshToken) => {
  localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
};

const getToken = () => {
  return localStorage.getItem(TOKEN_KEY);
};

const getRefreshToken = () => {
  return localStorage.getItem(REFRESH_TOKEN_KEY);
};

const removeToken = () => {
  localStorage.removeItem(TOKEN_KEY);
};

const removeRefreshToken = () => {
  localStorage.removeItem(REFRESH_TOKEN_KEY);
};

const isAuthenticated = () => {
  return !!getToken();
};

export { setToken, setRefreshToken, getToken, getRefreshToken, removeToken, removeRefreshToken, isAuthenticated };
