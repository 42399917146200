import React, { useState } from "react";
import "./LendingEcosystem.scss";

import SESlogo from "./assets/Lending.png";
import emptyimg from "./assets/feature2.svg.png";
import Lending from "./assets/Marketing.svg";
import techstatsdata from "./techstatdata";
import { useRef } from "react";
const LendingEcosytem = () => {
  const elementsRef = useRef([]);

  const handleMouseMove = (e, index) => {
    const rect = elementsRef.current[index].getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    elementsRef.current[index].style.setProperty('--x', `${x}px`);
    elementsRef.current[index].style.setProperty('--y', `${y}px`);
  };

  const registerElementRef = (ref) => {
    if (ref && !elementsRef.current.includes(ref)) {
      elementsRef.current.push(ref);
    }
  };

  return (
    <div className="SES">
      <div className="SES1">
        <img src={SESlogo} alt="transbnk logo" className="SESimg" />
        <div className="SES1contentWrap">
          <h2 className="SES1headingS">
            Empower Your Business with <br />
            Our <h2 className="SES1heading">Lending Ecosystem Enabler Stack</h2>
          </h2>
          <h2 className="SES1content">
            Accelerate Growth, Boost Efficiency, and Enhance Customer Experience
            with Our Comprehensive Lending Solutions
          </h2>
        </div>
      </div>

      <div className="SES2">
        <a className="SES2Card mouse-cursor-gradient-tracking" href="/Docuflow"
          key={1}
          ref={registerElementRef}
          onMouseMove={(e) => handleMouseMove(e, 1)}

        >
          <svg
            width="55"
            height="55"
            viewBox="0 0 79 79"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="39.666"
              cy="39.6008"
              r="38.927"
              fill="#66BB94"
              fill-opacity="0.15"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M35.4881 22.1551C35.8007 21.8426 36.2246 21.667 36.6667 21.667H50C52.7615 21.667 55 23.9056 55 26.667V53.3337C55 56.0952 52.7615 58.3337 50 58.3337H30C27.2386 58.3337 25 56.0952 25 53.3337V33.3337C25 32.8916 25.1756 32.4677 25.4882 32.1551L35.4881 22.1551ZM50 25.0003H38.3333V33.3337C38.3333 34.2541 37.5872 35.0003 36.6667 35.0003H28.3333V53.3337C28.3333 54.2542 29.0795 55.0003 30 55.0003H50C50.9205 55.0003 51.6667 54.2542 51.6667 53.3337V26.667C51.6667 25.7465 50.9205 25.0003 50 25.0003ZM30.6903 31.667H35V27.3573L30.6903 31.667ZM31.6667 41.667C31.6667 40.7465 32.4129 40.0003 33.3333 40.0003H46.6667C47.5872 40.0003 48.3333 40.7465 48.3333 41.667C48.3333 42.5875 47.5872 43.3337 46.6667 43.3337H33.3333C32.4129 43.3337 31.6667 42.5875 31.6667 41.667ZM31.6667 48.3337C31.6667 47.4132 32.4129 46.667 33.3333 46.667H46.6667C47.5872 46.667 48.3333 47.4132 48.3333 48.3337C48.3333 49.2542 47.5872 50.0003 46.6667 50.0003H33.3333C32.4129 50.0003 31.6667 49.2542 31.6667 48.3337Z"
              fill="#66BB94"
            />
          </svg>

          <div className="SES2ContentWrap">
            <h2 className="SES2heading">Docuflow</h2>
            <h2 className="SES2content">
              Streamline your payments with our Recurring Payments Services,
              ensuring timely and hassle-free transactions
            </h2>
          </div>
        </a>
        <a className="SES2Card" href="/RiskRadar" ref={registerElementRef}
          key={2}
          onMouseMove={(e) => handleMouseMove(e, 2)}
        >
          <svg
            width="55"
            height="55"
            viewBox="0 0 79 79"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="39.542"
              cy="39.6008"
              r="38.927"
              fill="#66BB94"
              fill-opacity="0.15"
            />
            <path
              opacity="0.4"
              d="M39.4998 43.25C41.5709 43.25 43.2498 41.5711 43.2498 39.5C43.2498 37.4289 41.5709 35.75 39.4998 35.75C37.4286 35.75 35.7498 37.4289 35.7498 39.5C35.7498 41.5711 37.4286 43.25 39.4998 43.25Z"
              stroke="#66BB94"
              stroke-width="2.8125"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M54.4998 50.75C56.8623 47.6188 58.2498 43.7188 58.2498 39.5C58.2498 35.2812 56.8623 31.3812 54.4998 28.25"
              stroke="#66BB94"
              stroke-width="2.8125"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M24.4998 28.25C22.1373 31.3812 20.7498 35.2812 20.7498 39.5C20.7498 43.7188 22.1373 47.6188 24.4998 50.75"
              stroke="#66BB94"
              stroke-width="2.8125"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              opacity="0.4"
              d="M48.5012 46.25C49.9075 44.375 50.7512 42.0312 50.7512 39.5C50.7512 36.9687 49.9075 34.625 48.5012 32.75"
              stroke="#66BB94"
              stroke-width="2.8125"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              opacity="0.4"
              d="M30.4998 32.75C29.0935 34.625 28.2498 36.9687 28.2498 39.5C28.2498 42.0312 29.0935 44.375 30.4998 46.25"
              stroke="#66BB94"
              stroke-width="2.8125"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <div className="SES2ContentWrap">
            <h2 className="SES2heading">Risk Radar</h2>
            <h2 className="SES2content">
              Monitor Escrow accounts effortlessly and streamline the process
              flow and empowers users to focus on what truly matters.
            </h2>
          </div>
        </a>
        <a className="SES2Card" href="/SCF">
          <svg
            width="55"
            height="55"
            viewBox="0 0 79 79"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="39.4187"
              cy="39.6008"
              r="38.927"
              fill="#66BB94"
              fill-opacity="0.15"
            />
            <path
              d="M46.707 41.8291C44.4746 41.8291 42.6641 43.6396 42.6641 45.8721C42.6641 48.1045 44.4746 49.915 46.707 49.915C48.9395 49.915 50.75 48.1045 50.75 45.8721C50.75 43.6396 48.9395 41.8291 46.707 41.8291ZM46.707 47.3662C45.8809 47.3662 45.2129 46.6982 45.2129 45.8721C45.2129 45.0459 45.8809 44.3779 46.707 44.3779C47.5332 44.3779 48.2012 45.0459 48.2012 45.8721C48.2012 46.6982 47.5332 47.3662 46.707 47.3662ZM55.6719 24.3828H46.3555C46.3555 23.0249 45.2524 21.9219 43.8945 21.9219H40.3789C39.021 21.9219 37.918 23.0249 37.918 24.3828H28.6016C27.8237 24.3828 27.1953 25.0112 27.1953 25.7891V34.5781H23.3281C22.5503 34.5781 21.9219 35.2065 21.9219 35.9844V55.6719C21.9219 56.4497 22.5503 57.0781 23.3281 57.0781H38.0938C38.8716 57.0781 39.5 56.4497 39.5 55.6719V54.9688H55.6719C56.4497 54.9688 57.0781 54.3403 57.0781 53.5625V25.7891C57.0781 25.0112 56.4497 24.3828 55.6719 24.3828ZM37.5664 27.1953H40.7305V24.7344H43.543V27.1953H46.707V29.3047H37.5664V27.1953ZM36.6875 54.2656H24.7344V44.0703H36.6875V54.2656ZM36.6875 41.2578H24.7344V37.3906H36.6875V41.2578ZM53.9141 51.8047H39.5V35.9844C39.5 35.2065 38.8716 34.5781 38.0938 34.5781H30.3594V27.5469H34.7539V32.1172H49.5195V27.5469H53.9141V51.8047ZM47.9375 34.9297V39.1484C47.9375 39.3418 48.0957 39.5 48.2891 39.5H50.3984C50.5918 39.5 50.75 39.3418 50.75 39.1484V34.9297C50.75 34.7363 50.5918 34.5781 50.3984 34.5781H48.2891C48.0957 34.5781 47.9375 34.7363 47.9375 34.9297ZM43.0156 39.5H45.125C45.3184 39.5 45.4766 39.3418 45.4766 39.1484V36.6875C45.4766 36.4941 45.3184 36.3359 45.125 36.3359H43.0156C42.8223 36.3359 42.6641 36.4941 42.6641 36.6875V39.1484C42.6641 39.3418 42.8223 39.5 43.0156 39.5Z"
              fill="#66BB94"
            />
          </svg>

          <div className="SES2ContentWrap">
            <h2 className="SES2heading">SCF Recon Platform</h2>
            <h2 className="SES2content">
              A digital ledger manager that offers a plug-and-play multi-bank
              model and invoice reconciliation capabilities, empowering
              businesses in supply chain finance.
            </h2>
          </div>
        </a>

        <a className="SES2Card" href="/Colending">
          <svg
            width="55"
            height="55"
            viewBox="0 0 79 79"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="39.4187"
              cy="39.6008"
              r="38.927"
              fill="#66BB94"
              fill-opacity="0.15"
            />
            <path
              d="M46.707 41.8291C44.4746 41.8291 42.6641 43.6396 42.6641 45.8721C42.6641 48.1045 44.4746 49.915 46.707 49.915C48.9395 49.915 50.75 48.1045 50.75 45.8721C50.75 43.6396 48.9395 41.8291 46.707 41.8291ZM46.707 47.3662C45.8809 47.3662 45.2129 46.6982 45.2129 45.8721C45.2129 45.0459 45.8809 44.3779 46.707 44.3779C47.5332 44.3779 48.2012 45.0459 48.2012 45.8721C48.2012 46.6982 47.5332 47.3662 46.707 47.3662ZM55.6719 24.3828H46.3555C46.3555 23.0249 45.2524 21.9219 43.8945 21.9219H40.3789C39.021 21.9219 37.918 23.0249 37.918 24.3828H28.6016C27.8237 24.3828 27.1953 25.0112 27.1953 25.7891V34.5781H23.3281C22.5503 34.5781 21.9219 35.2065 21.9219 35.9844V55.6719C21.9219 56.4497 22.5503 57.0781 23.3281 57.0781H38.0938C38.8716 57.0781 39.5 56.4497 39.5 55.6719V54.9688H55.6719C56.4497 54.9688 57.0781 54.3403 57.0781 53.5625V25.7891C57.0781 25.0112 56.4497 24.3828 55.6719 24.3828ZM37.5664 27.1953H40.7305V24.7344H43.543V27.1953H46.707V29.3047H37.5664V27.1953ZM36.6875 54.2656H24.7344V44.0703H36.6875V54.2656ZM36.6875 41.2578H24.7344V37.3906H36.6875V41.2578ZM53.9141 51.8047H39.5V35.9844C39.5 35.2065 38.8716 34.5781 38.0938 34.5781H30.3594V27.5469H34.7539V32.1172H49.5195V27.5469H53.9141V51.8047ZM47.9375 34.9297V39.1484C47.9375 39.3418 48.0957 39.5 48.2891 39.5H50.3984C50.5918 39.5 50.75 39.3418 50.75 39.1484V34.9297C50.75 34.7363 50.5918 34.5781 50.3984 34.5781H48.2891C48.0957 34.5781 47.9375 34.7363 47.9375 34.9297ZM43.0156 39.5H45.125C45.3184 39.5 45.4766 39.3418 45.4766 39.1484V36.6875C45.4766 36.4941 45.3184 36.3359 45.125 36.3359H43.0156C42.8223 36.3359 42.6641 36.4941 42.6641 36.6875V39.1484C42.6641 39.3418 42.8223 39.5 43.0156 39.5Z"
              fill="#66BB94"
            />
          </svg>

          <div className="SES2ContentWrap">
            <h2 className="SES2heading">Co-Lending Platform</h2>
            <h2 className="SES2content">
              Unlock the potential of collaborative lending with our innovative
              platform, connecting lenders and co-lenders for mutual financial
              success.
            </h2>
          </div>
        </a>
      </div>
    </div>
  );
};

export default LendingEcosytem;
