import React, { useState } from "react";
import {
  Nav,
  Navbar,
  NavDropdown,
  Col,
  Row,
  Container,
  Dropdown,
  Modal,
  Button,
} from "react-bootstrap";
import close from "../assets/iclose.svg";
import "./Product.scss";
import Contact from "../Contact";
import { useNavigate } from "react-router-dom";
// import test from "../../public
function PayoutsAPI() {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <>
      <iframe
        title="Payment Mandate"
        src={"/payment-mandate-apis/index.html"}
        // src={"/test/index.html"}
        id="widget"
        name="widget"
        scrolling="auto"
        frameBorder="0"
        width="100%"
        style={{ position: "relative" }}
      ></iframe>
    </>
  );
}

export default PayoutsAPI;
