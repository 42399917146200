import React from 'react'
import './Advisors.scss'
import { useState } from 'react';
import PrasannaLohar from "./assets/prasanna.png";
import Asit from "./assets/asit.png";
import Vasant from "./assets/vasanth.png";
import Pratek from './assets/pratekk.png'
// import Amit from "./assets/amit.png";
// import Dilip from "./assets/Dilip.png";
// import Parijat from "./assets/Parijat.png";
function Advisors() {
    const data = [
        {
            name: "Prasanna Lohar",
            img: PrasannaLohar,
            role: "Blockchain",
            desc: `    23 + Years exp CEO
          Block Stack | DCB Bank
          CIO Chief Innovation
          Officer| Blockchain
          Consortium Co-Chair
          with IAMAI & FICCI |
          Top 50 CIO | Top 50
          Influential Leader |
          Top 20 BFSI Leadership`,
        },
        {
            name: "Vasanth Jeyapaul",

            img: Vasant,
            role: "Management",
            desc: `CEO CAMSPay | Key
          Mgmt professional |
          Payments /Fintech/
          Digital Expert | Certified
          Board Director | BFSI
          |The University of
          Chicago Booth School of
          Business |Global
          Volunteer | Ex-FSS`,
        },
        {
            name: "Asit Oberai",

            img: Asit,
            role: "Transaction Banking",
            desc: ` Ex – MD APAC (Buckzy
            Payments) Experienced
            Head of Transaction
            Banking Ex- COO / CFO
            Banking roles Yes Bank
            | SCB | BofA |Financial
            Risk Mgmt. | Business
            Relationship Mgmt.|
            Institute of Chartered
            Accountants of India.
            `,
        },
        // {
        //     name: "Amit Tyagi",

        //     img: Amit,
        //     role: "Strategy & Investments",
        //     desc: `16+ Years Banking &
        //   Fintech Experience |
        //   ISB PGP’12 | CoFounder iServeU
        //   Technology, a full stack
        //   Fin Infra company
        //   which provides Banking
        //   as a Service | Extolabs
        //   LLC (USA) | Ex IndusInd
        //   Bank, Yes Bank

        //     `,
        // },
        // {
        //   name: "Sandeep Jhingran",

        //   img: Sandeep,
        //   role: "Global Remittances",
        //   desc: `IAMAI |BACC |
        //   CoFounder COO
        //   Remitware Payments INC
        //   20+ years of experience
        //   in Banking, Cross Border
        //   Remittance Payments,
        //   Fintech, public advocacy.
        //   treasury, Products, risk,
        //   compliance | financial
        //   business development.
        //     `,
        // },
        // {
        //     name: "Parijat Garg",

        //     img: Parijat,
        //     role: "Lending Ecosystem",
        //     desc: `20+ years in credit,
        //   lending, cards and
        //   data| Fintech | Digital
        //   Lending | Credit Risk &
        //   Underwriting| Credit
        //   Score| Digital &
        //   Business Strategy |
        //   Leveraging Data| BFSI
        //   B2B Sales & Marketing
        //   | CRIF | INFOSYS

        //     `,
        // },
        // {
        //     name: "Dilip Krishnan",

        //     img: Dilip,
        //     role: "Global Business",
        //     desc: `Global Lead - Digital
        //   Transformation Practice
        //   MasterCard, Singapore
        //   I Forbes Tech Council
        //   Member | WEF Expert
        //   member | Keynote
        //   Speaker | Mentor |
        //   Advisory Board
        //   Members to Fintech |
        //   CITI | OCBC
        //     `,
        // },


        {
            name: "Pratekk Agarwaal",

            img: Pratek,
            role: "Early Stage Investor & Advisor , Ex CBO BharatPe",
            desc: `His passion is helping founders build successful and scalable businesses by using his network, business advice, and raising money. Prateek is an alumnus of IIM-Kozhikode. Prateek advises several startups, NBFCs, large fintechs, and venture capital firms. Today is the best time to invest in the right founders and products.In his past roles at Bajaj Finserv, Fullerton, IndoStar Capital, and ftcash, Pratekk has built organizations at scale such as Bajaj Finserv, Fullerton, and IndoStar Capital.
            `,
        },
    ];
    const [selectedAdv, setselectedAdv] = useState("Prasanna Lohar")
    return (
        <div className='Advisors'>
            <div className="Advisors1"
            // style={{ backgroundImage: `url(${data.find(item => item.name === selectedAdv).img})`
            //  }}
            >
                <div className="Advisors1left"
                >
                    <img src={data.find(item => item.name === selectedAdv).img} alt="" />
                    <div className="advtextwrap"
                    >
                        <p className="headingAdvisors">
                            {data.find(item => item.name === selectedAdv).name}
                        </p>
                        <p className="contentAdvisors">
                            {data.find(item => item.name === selectedAdv).desc}
                        </p>
                    </div>
                </div>
                <div className="thumbnailWrap">
                    {
                        data.map((x) => (
                            <img src={x.img} alt={x.name} className='advthumbnail' onClick={() => {
                                setselectedAdv(x.name)
                            }} />
                        ))
                    }
                </div>
            </div>
        </div>
    )



}

export default Advisors