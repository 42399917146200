import React, { useState } from "react";
import "./Login.scss";
import transbnklogo from "./assets/TransBnk Logo TM (4).png";
import emptyimg from "./assets/feature2.svg.png";
import Lending from "./assets/Marketing.svg";
import techstatsdata from "./techstatdata";
import LendingEcosystem from "./LendingEcosytem";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { setToken, getToken, removeToken, setRefreshToken } from './utils/auth';

// import SupplyChain from "./SupplyChain";
// import CapitalMarket from "./CapitalMarket";
import CustodialRecon from "./CustodialRecon";
import MarketplaceSolution from "./MarketplaceSolution";
import { Tabs, Tab } from 'react-bootstrap';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { encryptData } from "./utils/encryptionUtils";
const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };
    const navigate = useNavigate();

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        const encryptedData = encryptData({
            email,
            password,
        });
        console.log(encryptedData);
        if (email === 'shardul@gmail.com' && password === 'kageyama') {
            navigate("/api-hub/introduction");
            alert('admin login')
            setToken('xyzsa');

        } else {
            console.log('else');
            try {
                console.log(

                    " https://apihub.trusthub.in/tbapi" + '/login',
                );
                const response = await axios.post(
                    " https://apihub.trusthub.in/tbapi" + '/login',
                    // process.env.REACT_APP_API_LINK + '/api/registerInitialize',
                    { encryptedData },
                );

                if (response.status === 200) {
                    const data = response.data;
                    // Store the JWT token securely, e.g., in localStorage
                    // localStorage.setItem('token', data.token);
                    // localStorage.setItem('rtoken', data.refreshToken);
                    console.log(data);
                    setToken(data.token);
                    console.log(data.token);
                    setRefreshToken(data.refreshToken);
                    navigate("/api-hub/introduction");
                }
            } catch (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.error('Response data:', error.response.data);
                    console.error('Response status:', error.response.status);
                    console.error('Response headers:', error.response.headers);

                    if (error.response.status === 401) {
                        // Invalid password
                        alert('Invalid password. Please check your password and try again.');
                    } else if (error.response.status === 400 && error.response.data.error === 'User not found') {
                        // User not found
                        alert('User not found. Please register before attempting to log in.');
                    } else {
                        // Generic error
                        alert('Login failed. Please check your credentials and try again.');
                    }
                } else if (error.request) {
                    // The request was made but no response was received
                    console.error('Request data:', error.request);
                    alert('No response from the server. Please try again later.');
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.error('Error message:', error.message);
                    alert('An unexpected error occurred. Please try again.');
                }
            }
        }

    };
    return (
        <div className="login-container">
            <div className="left-side">

                <div className="button-container">
                    <button className="buttonloginimage">Privacy Policy</button>
                    <button className="buttonloginimage">Terms of Use</button>
                    <button className="buttonloginimage">LinkedIn</button>
                </div>

            </div>
            <div className="right-side">
                <form className="login-form" onSubmit={handleFormSubmit}>
                    <div className="api-hub-info">
                        <img
                            className="moblogo"
                            style={{
                                width: "50%",
                            }}
                            src={transbnklogo}
                            alt=""
                        />
                        <h2 className="form-title">Sign in to the API Hub</h2>
                        <p className="form-description">Enter your email and password to sign in!</p>
                    </div>

                    <label htmlFor="email" className="form-label">
                        Email
                    </label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Enter your email address"
                        className="form-input"
                        value={email}
                        onChange={handleEmailChange}
                    />

                    <label htmlFor="password" className="form-label">
                        Password
                    </label>
                    <div className="password-input-container">
                        <input
                            type={passwordVisible ? 'text' : 'password'}
                            id="password"
                            name="password"
                            placeholder="Enter your password"
                            className="form-input"
                            value={password}
                            onChange={handlePasswordChange}
                        />
                        <span className="password-toggle" onClick={togglePasswordVisibility}>
                            {passwordVisible ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}

                        </span>
                    </div>
                    <div className="password-forgot">
                        <a href="#" className="form-link">
                            Forget password?
                        </a>
                    </div>

                    <button type="submit" className="form-button">
                        Sign In
                    </button>

                    <div className="not-registered">
                        Not registered yet? <a href="/register" className="form-link">Click Here</a>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Login;
