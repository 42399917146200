import React from "react";
import "./Terms.scss";
const Terms = () => (
  <div className="terms" id="conditions">
    <div className="terms-content">
      <br />
      <br />
      <br />
      <h2 className="about_text"> Website Usage Terms and Conditions</h2>
      <p>
        <br />
      </p>
      <p>
        Welcome to a{" "}
        <a
          href="http://www.transbnk.co.in"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.transbnk.co.in
        </a>
        , the website of TRANSBNK SOLUTIONS PRIVATE LIMITED, a company
        registered under the Companies Act,2013, India having its registered
        office at M-32, M Block Market, Greater Kailash II, New Delhi, INDIA -
        110048 &nbsp;and its principal place of business at, M-32, M Block
        Market, Greater Kailash II, New Delhi, INDIA - 110048, hereinafter
        referred to as &ldquo;Transbnk&rdquo; , &ldquo;TransBnk&rdquo;,
        &rdquo;we&rdquo;, &rdquo;us&rdquo;, &ldquo;Company&rdquo;, &ldquo;1st
        Party&rdquo;.
      </p>
      <p>
        <br />
      </p>
      <p>
        The user using this Website (the &quot;Site&quot;) and the services made
        available on the Site or on Applications, Tools, Plugins, Extensions,
        APIs published on the Site and available for distribution on registered,
        recognized and official 3rd party platforms, is subject to these Terms
        of Service (these &quot;Terms&quot;, &ldquo;Agreement&rdquo;).
      </p>
      <p>
        <br />
      </p>
      <p>
        Transbnk provides Software and services for paperless, hassle-free
        documentation and digitization of banking journeys. Software and
        services include but are not limited to, generation, facilitation,
        management and distribution of electronic signatures, storage, sharing,
        digitization, validation and Escrow consultancy, documents
        templatization of document based on the user&rsquo;s identity. (the
        &rdquo;Services&rdquo;).
      </p>
      <p>
        <br />
      </p>
      <p>
        The Individual or Company that the Individual is representing, intending
        to use or using our Services, whether in individual capacity or
        representing a registered legal entity, is hereinafter referred to as
        &ldquo;Customer&rdquo;, &ldquo;User&rdquo;, &ldquo;you&rdquo;,
        &ldquo;him/her&rdquo;, &ldquo;they&rdquo;, &ldquo;2nd Party&rdquo;.
      </p>
      <p>
        <br />
      </p>
      <p>
        Collectively, Company and User to be hereinafter referred to as
        &ldquo;Parties&rdquo; and Each a &ldquo;Party&rdquo;
      </p>
      <p>
        <br />
      </p>
      <p>
        By using any of our Services, you agree to be bound by, and use our
        Services in compliance with, these Terms of Service in entirety. IF YOU
        DO NOT AGREE TO THESE TERMS OF SERVICE, WE ENCOURAGE YOU NOT TO PROCEED
        WITH USE OF OUR SERVICES.
      </p>
      <p>
        <br />
      </p>
      <p>
        This page will be updated whenever there is a change to the Terms. It is
        your responsibility to check this URL frequently to keep yourself
        informed about Transbnk&rsquo;s Terms. The then-current version of these
        Terms will supersede all earlier versions. You agree that your continued
        use of our Services after such changes have been published to our
        Services will constitute your acceptance of such revised Terms.
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>Ownership of Content</strong>
      </p>
      <p>
        <br />
      </p>
      <p>
        Transbnk doesn&rsquo;t have any license for or doesn&rsquo;t claim
        ownership rights pertaining to any documents, files submitted by you to
        its platform for using its Services (&ldquo;Content&rdquo;). These Terms
        do not grant us any licenses or rights to your Content except for the
        limited rights needed for us to provide the Services to you. Limited
        rights include but are not limited to copying, storing, extracting data,
        verifying, sharing Content for purposes of research and development,
        gain or otherwise, but in complete accordance with Transbnk&rsquo;s
        Privacy Policy and/or applicable laws of the land, and with your full
        consent.
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>Rights &amp; Responsibilities of the User</strong>
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>Rights</strong>
      </p>
      <p>
        <br />
      </p>
      <p>
        User can subscribe to all or part of Services, as is offered by the
        Company and made available to the User from time to time.
      </p>
      <p>
        User can discontinue/cancel/unsubscribe his/her subscription/account for
        all or part of Services subscribed to from the Company anytime. However,
        in such an event the Company is not liable to refund any fees paid by
        the User to the Company towards use of Services.
      </p>
      <p>
        User can anytime unsubscribe from promotional communication that may be
        sent by the Company to the User from time to time. However, this is
        limited to promotional communication only. Transactional and
        Informational communication may still be sent to the User by the
        Company, as this is necessary for delivering the Services to the User.
      </p>
      <p>
        User has complete ownership rights on Content submitted by him/her to
        the Company as described in &ldquo;Ownership of Content&rdquo; above.
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>Responsibilities</strong>
      </p>
      <p>
        <br />
      </p>
      <p>
        User takes full responsibility of the Content submitted by him/her to
        the Company. Any and all claims that may arise pertaining to the
        submitted Content, including but not limited to Copyright, Trademark,
        License, will be the User&rsquo;s responsibility.
      </p>
      <p>
        User assures the Company that Content submitted by him/her to the
        Company is free from virus, trojans, malware, malicious scripts etc.
      </p>
      <p>
        It is the User&rsquo;s responsibility to keep his/her access credentials
        to the Site safe and protected from unintended use. The User further
        agrees to notify the Company if the password is lost, stolen, disclosed
        to an unauthorized third party, or otherwise may have been compromised
        and takes full responsibility for any and all activities that occur
        under the User&rsquo;s account, whether or not you as the User is the
        individual who undertakes such activities. The User agrees to
        immediately notify the Company of any unauthorized use of User&rsquo;s
        account or any other breach of security in relation to password or our
        Services that is known to the User.
      </p>
      <p>
        It is the User&rsquo;s responsibility to respect all prior
        agreements/policies, if any, that the User may be party, to directly or
        indirectly, pertaining to the Confidentiality of the Content submitted
        to the Company. The Company can not be held responsible or liable in any
        manner in an eventuality of the Content submitted by the User to the
        Company containing any Confidential information that the User is not
        supposed to submit as per prior agreements/policies he/she may be party
        too.
      </p>
      <p>User will not use our Services to:</p>
      <p>
        upload, post, email, or otherwise transmit any Submission that contains
        unlawful, harmful, threatening, abusive, harassing, tortious,
        defamatory, vulgar, obscene, libelous, invasive of another&apos;s
        privacy, hateful, or racially, ethnically or otherwise objectionable;
      </p>
      <p>harm us or third parties in any way;</p>
      <p>
        impersonate any person or entity, or otherwise misrepresent your
        affiliation with a person or entity;
      </p>
      <p>
        upload, post, email, or otherwise transmit any Submission that you do
        not have a right to transmit under any law or under contractual or
        fiduciary relationships (such as inside information, proprietary and
        confidential information learned or disclosed as part of employment
        relationships or under nondisclosure agreements);
      </p>
      <p>
        upload, post, email or otherwise transmit any Submission that infringes
        any patent, trademark, trade secret, copyright, or other right of any
        party;
      </p>
      <p>
        upload, post, email, or otherwise transmit any unsolicited or
        unauthorized advertising, promotional materials, &quot;junk mail,&quot;
        &quot;spam,&quot; &quot;chain letters,&quot; &quot;pyramid
        schemes,&quot; or any other forms of solicitation;
      </p>
      <p>
        upload, post, email, or otherwise transmit any material that contains
        software viruses or any other computer code, files, or programs designed
        to interrupt, destroy, or limit the functionality of any computer
        software or hardware or telecommunications equipment;
      </p>
      <p>
        interfere with or disrupt the Services or servers or networks connected
        to the Services, or disobey any requirements, procedures, policies or
        regulations of networks connected to the Services;
      </p>
      <p>
        intentionally or unintentionally violate any applicable local, state,
        national or international law or regulation;
      </p>
      <p>&quot;stalk&quot; or otherwise harass another; or</p>
      <p>collect or store personal data about other users.</p>
      <p>
        User agrees that user or its computer resources shall not host, display,
        upload, modify, publish, transmit, store, update or share any
        information that,&mdash; (i) belongs to another person and to which the
        user does not have any right; (ii) is defamatory, obscene, pornographic,
        paedophilic, invasive of another&lsquo;s privacy, including bodily
        privacy, insulting or harassing on the basis of gender, libellous,
        racially or ethnically objectionable, relating or encouraging money
        laundering or gambling, or otherwise inconsistent with or contrary to
        the laws in force; (iii) is harmful to child; (iv) infringes any patent,
        trademark, copyright or other proprietary rights; (v) violates any law
        for the time being in force; (vi) deceives or misleads the addressee
        about the origin of the message or knowingly and intentionally
        communicates any information which is patently false or misleading in
        nature but may reasonably be perceived as a fact; (vii) impersonates
        another person; (viii) threatens the unity, integrity, defence, security
        or sovereignty of India, friendly relations with foreign States, or
        public order, or causes incitement to the commission of any cognizable
        offence or prevents investigation of any offence or is insulting other
        nation; (ix) contains software virus or any other computer code, file or
        program designed to interrupt, destroy or limit the functionality of any
        computer resource; (x) is patently false and untrue, and is written or
        published in any form, with the intent to mislead or harass a person,
        entity or agency for financial gain or to cause any injury to any
        person;
      </p>
      <p>
        User agrees to only use trusted and secure computing devices (computer,
        mobile, tablet) and networks to access his/her account on the Site. If
        the User is using any shared or public device to access the Site and
        Services, the User is completely responsible for any risk associated
        with such activity.
      </p>
      <p>
        The User shall be strictly responsible for any third party claims and
        actions that may arise solely owing to the breach of the terms and
        conditions contained herein by the User. Further, the User shall also
        assume any and all liability and responsibility for such of User&rsquo;s
        actions which result in the breach of the terms and conditions contained
        herein or the violation of any domestic or international laws.
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>Rights &amp; Responsibilities of the Company</strong>
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>Rights</strong>
      </p>
      <p>
        <br />
      </p>
      <p>
        The Company shall have the right to discontinue offering free services,
        if any for the User at any point in time.
      </p>
      <p>
        The Company shall have the right to limit the quantum of free services
        to any number it desires at any point in time.
      </p>
      <p>
        The Company shall have the right to refuse offering Services to the User
        if the User does not or fails to register himself/herself/itself with
        the Company for specific services requiring pre-requisites, mandatory
        registrations etc as published by the Company from time to time
      </p>
      <p>
        If a subscription is not discontinued, cancelled by the User, the
        Company has the right to renew the subscription as per subscribed plan
        and associated terms automatically, without prior initiation from the
        User. In such an event, no refund of fees will be processed.
      </p>
      <p>
        User grants the Company the right to send him/her any and all
        Transactional and Informational communication anytime by any mode of
        communication as furnished by the User to the Company.
      </p>
      <p>
        In case of Content submitted by User is found to contain virus, trojans,
        malware, malicious scripts etc, the Company reserves the right to
        prevent such Content from getting submitted to its Site.
      </p>
      <p>
        If the Company detects any suspicious activity on the User&rsquo;s
        account, the Company reserves the right to forcefully terminate the
        logged in session, automatically change access credentials and send
        appropriate communication to the User
      </p>
      <p>
        Company has the right to publish, quote, cite the User and/or his/her
        Organization&rsquo;s Logo, Use of Services, Comments about the Site
        and/or Services on the Site or on 3rd Party sites, in electronic or
        print form, either in part or whole for activities including but not
        limited to, case studies, marketing, advertising itself, its Site and/or
        Services without prior consent of the User or his/her Organization,
        whether in writing or oral.
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>Responsibilities</strong>
      </p>
      <p>
        <br />
      </p>
      <p>
        The Company bears responsibility for the security the Site and
        associated systems.
      </p>
      <p>
        It is the Company&rsquo;s responsibility to deliver Services to the best
        of standard and be cognizant of the fact that any service disruption may
        disrupt the User&rsquo;s work. The Company will try to maintain Service
        uptime to the highest level possible.
      </p>
      <p>
        The Company is responsible for performing periodic upgrade and
        maintenance activities as identified by the Company, and shall
        communicate regarding any scheduled Service downtime arising out of such
        instances, to the User either published on the Site or via.
        Communication sent to the User directly.
      </p>
      <p>
        Company shall be responsible in maintaining the Privacy of User and
        Content as outlined in the Privacy Policy
      </p>
      <p>
        It is the Company&rsquo;s responsibility to provide the User with
        Invoices and Receipts for all Fees to be paid or already paid by the
        User
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>Intellectual Property Rights</strong>
      </p>
      <p>
        <br />
      </p>
      <p>
        The User acknowledges and agrees that all the rights, title and
        interests in the Site and/or the Services and all the Intellectual
        Property rights therein or related thereto are solely and absolutely
        owned by the Company or its licensor and shall continue to vest with the
        Company or its licensor during and after the term of this Agreement.
      </p>
      <p>
        Nothing in this Agreement provides any right, title and interests of
        license, assignment or ownership in the Site and/or the Services or the
        Intellectual Property rights therein or associated therewith, and the
        Company reserves all rights in relation thereto not expressly granted to
        the User under these Terms.
      </p>
      <p>
        The User hereby undertakes that the User shall not apply, either by
        himself/ herself/itself or through any third party, for any copyright,
        trademark or any Intellectual Property for any aspect of the
        Intellectual Property rights relating to the Site and/or the Services,
        whether or not such Intellectual Property has been provided under this
        Agreement or developed by the Company for the purposes of this
        Agreement.
      </p>
      <p>
        The User agrees not to (i) copy, modify, create any derivative work of,
        or include in any other products or any portion thereof, or (ii) either
        directly or indirectly decompile, disassemble, decipher, reverse
        engineer, re-engineer or otherwise attempt to derive Source Code or the
        underlying ideas, algorithms, structure or organization from the Company
        and/or the Site or directly or indirectly permit any employee/personnel
        of the User to decompile, disassemble, decipher, reverse engineer,
        re-engineer or otherwise attempt to derive Source Code or the underlying
        ideas, algorithms, structure or organization from the Company and/or the
        Site.
      </p>
      <p>
        The Company may procure third party components such as software,
        solutions, etc., (&ldquo;Third Party Software&rdquo;), to develop the
        Site and/or the Services. In this regard, in the event the Company
        procures any Third Party Software in relation to the development of the
        Site, all title, ownership and rights to such Third Party Software shall
        continue to vest either with such third party or with the Company.
        Consequently, the User may only have a restricted right to use the Third
        Party Software in relation to the Site and/or the Services as
        contemplated under these Terms and the User shall have no further right,
        title or interest in such Third Party Software other than as
        specifically contemplated under these Terms.
      </p>
      <p>
        The User acknowledges that during the term of this Agreement and use of
        the Site and Services, the Third Party Software shall continue to be
        owned solely and exclusively by such relevant third parties or the
        Company, as specified above in this Agreement.
      </p>
      <p>
        The User agrees not to represent the Company, the Site and/or the
        Services to be his/her/its own product or services or brand or
        Intellectual Property and technology.
      </p>
      <p>
        In addition to the foregoing, the User shall always identify and respect
        the intellectual property rights of third parties over any subject
        matter and shall not use them in any manner during the Transactions,
        unless specifically authorized by the holder/s of such intellectual
        property rights.
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>License</strong>
      </p>
      <p>
        <br />
      </p>
      <p>
        Subject to the Terms, unless otherwise approved by Transbnk or
        it&rsquo;s authorized signatories, Transbnk hereby grants only a
        limited, non-exclusive, non-transferable license to the User to use
        it&rsquo;s Services for personal use and/or business purpose if approved
        by the authorized signatory of the Company the User is representing, and
        not for resale or distribution. Your rights to use the Services is
        limited to all terms &amp; conditions set forth in the Terms. Except for
        your pre-existing rights and this license granted to you, we reserve all
        rights in and to our Services, including all related intellectual
        property rights. Except as otherwise explicitly provided in these Terms
        or as may be expressly permitted by applicable law, or as approved by
        Transbnk or it&rsquo;s authorized signatories, you will not permit or
        authorize any third party to: (i) reproduce, modify, translate, enhance,
        decompile, disassemble, reverse engineer or create derivative works of
        any of our Services; (ii) rent, lease or sublicense access to any of our
        Services; or (iii) circumvent or disable any security or technological
        features or measures of our Services. Any rights not expressly granted
        herein are reserved by Transbnk.
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>Links and Third Party Content</strong>
      </p>
      <p>
        <br />
      </p>
      <p>
        Our Services may display, or contain links to, third party products,
        services, and Web sites. Any opinions, advice, statements, services,
        offers, or other information that constitutes part of the content
        expressed, authored, or made available by other users or other third
        parties on our Services, or which is accessible through or may be
        located using our Services (collectively, &quot;Third Party
        Content&quot;) are those of the respective authors or producers and not
        of us or our shareholders, directors, officers, employees, agents, or
        representatives. We do not control Third Party Content and do not
        guarantee the accuracy, integrity or quality of such Third Party
        Content. We are not responsible for the performance of, we do not
        endorse, and we are not responsible or liable for, any Third Party
        Content or any information or materials advertised in any Third Party
        Content. By using our Services, you may be exposed to content that is
        offensive, indecent, or objectionable. We are not be responsible or
        liable, directly or indirectly, for any damage or loss caused to you by
        your use of or reliance on any goods, services, or information available
        on or through any third party service or Third Party Content. It is your
        responsibility to evaluate the information, opinion, advice, or other
        content available on and through our Services.
      </p>
      <p>
        <br />
      </p>
      <p>
        Logo : We are not directly associated with certain logo&rsquo;s. Use of
        Startup India/MSME/ISO and logo of similar nature is just to represent
        our belief and vision aligned towards vision of campaign. We shall
        ensure that use of Digital India logo does not indicate or create false
        impression of endorsement, approval, sponsorship of affiliation of or
        with our website or for some products by Startup India or by other
        authorities. These Authorities reserves the right to take action against
        any use of the logo that does not confirm to any of the conditions
        herein, or that infringes any intellecual prperty or other rights or
        violates applicable law.
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>Consent for Communication</strong>
      </p>
      <p>
        <br />
      </p>
      <p>
        The User agrees that in order to use the Site and/or Services, he/she
        needs to provide certain consent as published by the Company on the Site
        from time to time. Such consent may include consent to receive
        communication regarding User activity, Transactions, Fees, Verification,
        Promotional communication etc by any and all mode of communication as
        deemed apt by the Company. The User can opt-out, unsubscribe of any
        Promotional communication at any point. However, the User agrees the
        User can not opt-out of any form of Communication that is required for
        the Company to deliver the Services. By providing such consent, the User
        agrees to not to file any complaint, legal suit, case with any
        Regulatory Authority and/or Legal body regarding receiving such
        communication from the Company or its Licensors and/or Third Party
        Software providers. The User further agrees that such communication can
        be sent to him/her by the Company at any time of the day via. Any and
        all mode of communication as deemed appropriate and necessary by the
        Company and will supercede all prior &ldquo;Do-Not-Disturb&rdquo;
        service as the User may have activated at that point. Suspension of
        Services
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>Privacy Policy</strong>
      </p>
      <p>
        <br />
      </p>
      <p>
        The Privacy Policy shall be read in conjunction with the Terms, is
        governed by the Terms and updated by the Company in the event of a
        change in the Company&rsquo;s Privacy Policy. The Privacy Policy shall
        be made available by the Company for the User&rsquo;s perusal at Privacy
        Policy . It is the User&rsquo;s responsibility to check Privacy Policy
        to stay informed about the current Privacy Policy of the Company
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>Trademarks</strong>
      </p>
      <p>
        <br />
      </p>
      <p>
        &quot;Transbnk,&quot; the Transbnk logo, and any other product or
        service name or slogan displayed on our Site and/or Services are
        trademarks of Transbnk Solutions Private Limited and its suppliers or
        licensors, and may not be copied, imitated or used, in whole or in part,
        without the prior written permission of Transbnk Solutions Private
        Limited or the applicable trademark holder. You may not use any metatags
        or any other &quot;hidden text&quot; utilizing &quot;Transbnk&quot; or
        any other name, trademark or product or service name of Transbnk
        Solutions Private Limited without our prior written permission. In
        addition, the look and feel of our Services, including all page headers,
        custom graphics, button icons and scripts, is the service mark,
        trademark and/or trade dress of transbnk and may not be copied,
        imitated, or used, in whole or in part, without our prior written
        permission. All other trademarks, registered trademarks, product names
        and company names or logos mentioned in our Services are the property of
        their respective owners. Reference to any products, services, processes
        or other information, by trade name, trademark, manufacturer, supplier,
        or otherwise does not constitute or imply endorsement, sponsorship, or
        recommendation thereof by us.
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>Feedback</strong>
      </p>
      <p>
        <br />
      </p>
      <p>
        We may provide you with a mechanism to provide feedback, suggestions,
        and ideas, if you choose, about our Services (&quot;Feedback&quot;). You
        agree that we may, in our sole discretion, use the Feedback you provide
        to us in any way, including in future enhancements and modifications to
        our Services. You hereby grant to us and our assigns a perpetual,
        worldwide, fully transferable, sublicensable, irrevocable, royalty free
        license to use, reproduce, modify, create derivative works from,
        distribute, and display the Feedback in any manner for any purpose, in
        any media, software, or technology of any kind now existing or developed
        in the future, without any obligation to provide attribution or
        compensation to you or any third party.
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>Representation, Warranties and Covenants</strong>
      </p>
      <p>
        <br />
      </p>
      <p>
        Each Party represents and warrants that it has the right to disclose to
        the other and grant the other Party access to any information disclosed,
        or that may be disclosed, to the other Party.
      </p>
      <p>
        The Company represents, warrants, and covenants to the User as follows;
        to the best knowledge of the Company, Company does not contain any
        program, or other internal component which could damage, destroy, or
        alter any data or other information accessed through or processed by the
        User in any manner. The User shall immediately advise the Company in
        writing, upon reasonable suspicion or actual knowledge of any program or
        internal component that the Company has provided under this Agreement
        may result in the harm described above, and the Company will use best
        commercial efforts to get such program or
      </p>
      <p>
        The User hereby represents and warrants that he/she/it shall not utilize
        the Site and/or Services except for the purposes specifically
        contemplated under this Agreement or any other agreement entered into
        with the Company or specific purposes detailed by the Company separately
        and such purposes shall automatically form part of this Agreement. In
        the event that the Company learns that the User has utilized the Site
        and/or the Services for purposes other than what is specified in the
        Agreement, then the User shall be liable to indemnify the Company for
        such unauthorized use, including any third party claims arising
        therefrom.
      </p>
      <p>
        The User hereby represents and agrees that he/she/it shall refrain from
        publishing to the public or provide access to the public, using the Site
        and/or the Services or affiliated third party services, any content over
        which a third party has exclusive intellectual property rights or
        requisite protection thereunder or content which is derogatory,
        defamatory, slanderous, lascivious, pornographic, appealing to the
        prurient interest of individuals, and anything else which may incite
        public, political or religious unrest or disharmony, which may or may
        not result in affecting the law and order situation. However, if the
        User violates this particular clause, the User undertakes to bear all
        liabilities and responsibilities, civil and criminal, arising from such
        action/s of the User and shall keep the Company insulated and
        indemnified from the same.
      </p>
      <p>
        The User hereby represents and warrants to the Company that in the event
        of any suits, claims, disputes or such differences as are brought
        directly or indirectly against the Company by a third party as a
        consequence of breach of the terms and conditions of this Agreement by
        the User, the User shall at his/its sole cost assist the Company in
        defending such suits, claims, disputes or differences or indemnify the
        Company for the same, and any loss arising therefrom.
      </p>
      <p>
        The User represents and warrants that no copies of this Site or any
        portions thereof may be made by the User or any person under the
        User&rsquo;s authority or control. The User agrees not to assign or
        share User&rsquo;s passwords and username(s), if any, with any person
        whosoever.
      </p>
      <p>
        The User acknowledges that the User will be entitled to use certain
        Services only upon completing the requisite registration formalities as
        provided on the Company&rsquo;s Site or elsewhere, as designated by the
        Company.
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>DISCLAIMER OF WARRANTIES</strong>
      </p>
      <p>
        <br />
      </p>
      <p>
        YOUR USE OF THE SERVICES AND THE SERVICE CONTENT IS AT YOUR SOLE RISK.
        THE SERVICES AND THE SERVICE CONTENT EACH ARE PROVIDED ON AN &quot;AS
        IS&quot; AND &quot;AS AVAILABLE&quot; BASIS. TO THE MAXIMUM EXTENT
        PERMITTED BY APPLICABLE LAW, WE AND OUR SUPPLIERS AND LICENSORS
        EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
        IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND
        NON-INFRINGEMENT. WE DO NOT WARRANT THE COMPREHENSIVENESS, CORRECTNESS,
        LEGALITY, OR ACCURACY OF THE SERVICE OR SERVICE CONTENT OR THAT THE
        SERVICE WILL BE UNINTERRUPTED OR ERROR FREE.. ANY MATERIAL THAT YOU
        ACCESS OR OBTAIN THROUGH OUR SERVICES IS DONE AT YOUR OWN DISCRETION AND
        RISK AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER
        OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY MATERIAL THROUGH
        OUR SERVICES. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN,
        OBTAINED BY YOU FROM US OR THROUGH OR FROM OUR SERVICES WILL CREATE ANY
        WARRANTY NOT EXPRESSLY STATED IN THESE TERMS.
      </p>
      <p>
        <br />
      </p>
      <p>Any rights not expressly granted herein are reserved by us.</p>
      <p>
        <br />
      </p>
      <p>
        <strong>LIMITATION OF LIABILITY</strong>
      </p>
      <p>
        <br />
      </p>
      <p>
        TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, WE AND OUR SUPPLIERS
        AND LICENSORS WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL,
        SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED
        TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA, OR OTHER
        INTANGIBLE LOSSES (EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF
        THESE DAMAGES), RESULTING FROM YOUR USE OF OUR SERVICES AND SERVICE
        CONTENT. UNDER NO CIRCUMSTANCES WILL THE TOTAL LIABILITY OF US AND OUR
        SUPPLIERS AND LICENSORS OF ALL KINDS ARISING OUT OF OR RELATED TO YOUR
        USE OF THE SERVICES AND SERVICE CONTENT (INCLUDING BUT NOT LIMITED TO
        WARRANTY CLAIMS), REGARDLESS OF THE FORUM AND REGARDLESS OF WHETHER ANY
        ACTION OR CLAIM IS BASED ON CONTRACT, TORT, OR OTHERWISE, EXCEED THE
        AMOUNTS, IF ANY, THAT YOU HAVE PAID TO US FOR YOUR USE OF THE SERVICES
        AND SERVICE CONTENT.
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>INDEMNITY</strong>
      </p>
      <p>
        <br />
      </p>
      <p>
        You will indemnify and hold us, our suppliers and licensors, and our
        respective subsidiaries, affiliates, officers, agents, employees,
        representatives, and assigns harmless from any costs, damages, expenses,
        and liability caused by your use of the Services and Service Content,
        your violation of these Terms, or your violation of any rights of a
        third party through use of the Services or Service Content.
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>Term of Agreement</strong>
      </p>
      <p>
        <br />
      </p>
      <p>
        The term of this Agreement will be coterminous with the period of a
        single usage or multiple usages, either intermittent or continuous, of
        the Company&rsquo;s Site and/or Services by the User.
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>MISCELLANEOUS</strong>
      </p>
      <p>
        <br />
      </p>
      <p>
        Force Majeure:None of the Parties shall be liable to the other for
        delays or failures in performance resulting from causes beyond the
        reasonable control of that Party, including, but not limited to, acts of
        God, labor disputes or disturbances, material shortages or rationing,
        riots, acts of war, governmental regulations, communication or utility
        failures, or casualties.
      </p>
      <p>
        Assignment: Parties shall not assign, in whole or in part, the benefits
        or obligations of this Agreement to any other person without the prior
        written consent of the other.
      </p>
      <p>
        Representation: The Parties acknowledge and agree that they have not
        entered into this Agreement in reliance on any representation statement
        or warranty (whether written or oral and whether express or implied)
        made by or on behalf of the other Party, other than such as are
        expressly set out herein.
      </p>
      <p>
        Entire Agreement: This Agreement constitutes the entire agreement
        between the User and the Company and pertains to the subject matter
        hereof and supersedes in their entirety over all other written or oral
        agreements between the Parties.
      </p>
      <p>
        Relationship between Parties: The Parties to this Agreement are
        independent persons/entities and nothing in this Agreement shall make
        them joint ventures, partners, employees, agents or other
        representatives of the other Party hereto and neither Party shall make
        any representation that suggest otherwise.
      </p>
      <p>
        Severability: If any provision of this Agreement is determined to be
        unenforceable for any reason, then the remaining provisions hereof shall
        remain unaffected and continue to operate in full force and effect.
      </p>
      <p>
        Modification: This Agreement supersedes any previous communications,
        representations, or agreements, verbal or written, related to the
        subject matter of this Agreement. This Agreement may not be modified or
        amended orally, impliedly, or in any manner not set forth in writing or
        permitted by this Agreement.
      </p>
      <p>
        Rights and Remedies &ndash; Waiver: All rights and remedies hereunder
        shall be cumulative and may be exercised singularly or concurrently. If
        any legal action is brought to enforce any obligations hereunder, the
        prevailing Party shall be entitled to receive its attorney&rsquo;s,
        fees, court costs and other collection expenses, in addition to any
        other relief it may receive. If the Company does not enforce any
        provision against the User, failure to enforce on that occasion shall
        not prevent enforcement on later occasions.
      </p>
      <p>
        Survival of Provisions: Notwithstanding any other provision to the
        contrary herein, terms, which by their nature survive termination or
        expiration of this Agreement shall bind the Parties following any
        expiration or termination of this Agreement, in addition to those
        otherwise specifically detailed in this Agreement.
      </p>
      <p>
        Jurisdiction and Governing Law: This Agreement shall be governed by the
        laws of India. All disputes, claims and actions arising out of this
        Agreement or its validity will be subject exclusively to the
        jurisdiction of the competent courts at Delhi, India, irrespective of
        the place of residence or business of the User, or the place from which
        the website www.transbnk.co.in and/or the Services are accessed or
        availed. The User specifically agrees that a part of cause of action for
        any legal action is deemed to have arisen at Bangalore owing to the
        Company&rsquo;s registered office of being situated at Bangalore and
        this Agreement having been partly executed at Bangalore, thereby vesting
        the courts at Bangalore with the jurisdiction to adjudicate such
        disputes. As such, the User waives any right to challenge the
        exclusivity of jurisdiction of Bangalore courts to adjudicate any
        disputes arising out of this Agreement in accordance with the laws of
        India.
      </p>
      <p>
        Headings and Sub-Headings: The Headings and sub-headings in this
        Agreement are for convenience only and do not affect the meaning of the
        relative clause.
      </p>
      <p>
        Defined Terms: Various terminology as defined in this Agreement shall be
        read and construed strictly and the said definitions are used and shall
        apply solely to this Agreement and not any other agreement/s which the
        User may execute with the Company.
      </p>
      <p>
        Notices: Any notice, direction or instruction given under this Agreement
        shall be in writing and delivered (i) in the case of the Company to the
        User, by email, details of which if provided by the User; and (ii) in
        the case of the User to the Company, by email to the Company&rsquo;s
        designated email{" "}
        <a href="mailto:info@transbnk.co.in">info@transbnk.co.in</a>
      </p>
      <br />
      <br />
      <br />
    </div>
  </div>
);

export default Terms;
