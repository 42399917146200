import ImageGallery from "react-image-grid-gallery";
const imagesArray = [
  {
    alt: "Image1's alt text",
    caption: "Fintech Festival 2023",
    src: "https://i.ibb.co/KXbjRbP/Whats-App-Image-2023-06-08-at-23-29-46.jpg",
  },
  {
    alt: "Image2's alt text",
    caption: "World BFSI Awards",
    src: "https://i.ibb.co/sKbGN3Z/Whats-App-Image-2023-06-08-at-23-29-47.jpg",
  },
  {
    alt: "Image3's alt text",
    caption: "Fintech Festival 2023",
    src: "https://i.ibb.co/S3JVCCk/Whats-App-Image-2023-06-10-at-18-48-24.jpg",
  },
  {
    alt: "Image1's alt text",
    caption: "Fintech Festival 2023",
    src: "https://i.ibb.co/8N2XTy4/Whats-App-Image-2023-06-10-at-18-48-25.jpg",
  },
  {
    alt: "Image2's alt text",
    caption: "Fintech Festival 2023",
    src: "https://i.ibb.co/PMHtd9T/Whats-App-Image-2023-06-10-at-18-48-25.jpg",
  },
  {
    alt: "Image3's alt text",
    caption: "World BFSI Awards",
    src: "https://i.ibb.co/3d33WmV/abfl-award-1-1-1.png",
  },
];

export default function Gallery() {
  return (
    <div>
      <br />
      <br />
      <h1 style={{ marginTop: "2rem", marginBottom: "2rem" }}>
        Awards & Recognitions
      </h1>
      <ImageGallery
        imgArray={imagesArray}
        columnWidth={500}
        gapSize={1}
        isLightboxEnabled={false}
      />
    </div>
  );
}
