import React from "react";
import Aatmasamaan from "./assets/Logo/Aatmasamaan.png";
import ABCL from "./assets/Logo/ABCL.png";
// import Auro from "./assets/Logo/auro Fintech.png";
import AxisBank from "./assets/Logo/Axis Bank.png";
import Bajaj_Finance_Housing from "./assets/Logo/Bajaj_Finance_Housing.png";
import BimaPay from "./assets/Logo/BimaPay.png";
import Blostem from "./assets/Logo/Blostem.png";
import CSBBank from "./assets/Logo/CSB Bank.png";
import CTPLio from "./assets/Logo/CTPL.io.png";
import Extolabs from "./assets/Logo/Extolabs.png";
import FederalBank from "./assets/Logo/Federal Bank.png";
import Fiddypay from "./assets/Logo/Fiddypay.png";
import FintranXT from "./assets/Logo/FintranXT.png";
import Greattr from "./assets/Logo/Greattr.png";
import Hindon from "./assets/Logo/Hindon.png";
import ICICIBank from "./assets/Logo/ICICI Bank.png";
import idfc from "./assets/Logo/idfc-first-bank-logo.png";
import Imparitive from "./assets/Logo/Imparitive.png";
import Indorient from "./assets/Logo/Indorient.png";
import indusind from "./assets/Logo/indusind-bank1.png";
import kotaklogo from "./assets/Logo/kotak-logo.png";
import Logo_Gupshup from "./assets/Logo/Logo_Gupshup.png";
import Mobileware from "./assets/Logo/Mobileware.png";
import Mufin from "./assets/Logo/Mufin.png";
import NSDLPayments from "./assets/Logo/NSDL Payments.png";
import nsdllogo from "./assets/Logo/nsdl-logo.png";
import RBLBank from "./assets/Logo/RBL Bank.png";
import Rupee from "./assets/Logo/Rupee.png";
import Rythymflows from "./assets/Logo/Rythym flows.png";
import signzy from "./assets/Logo/signzy-logo-black.png";
import Techstalwarts from "./assets/Logo/Techstalwarts logo.png";
import tlGS from "./assets/Logo/tlGS.png";
import XtendValue from "./assets/Logo/XtendValue.png";
import YesBank from "./assets/Logo/Yes Bank.png";
import Ypay from "./assets/Logo/Ypay.png";
import sbm from "./assets/Logo/sbm.png";
import HDFC from "./assets/Logo/HDFC.png";
import defen from "./assets/Logo/defen.png";
import selp from "./assets/Logo/selp.png";
import tran from "./assets/Logo/tran.png";
import card91 from "./assets/Logo/card91.png";
import vega from "./assets/Logo/vega.png";
import bajajfin from "./assets/Logo/bajajfin.png";
import nas from "./assets/Logo/nas.png";
import sunstone from "./assets/Logo/sunstone.webp";
import nimbbl from "./assets/Logo/nimbbl.png";
import Auro from "./assets/Logo/Auro.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Marquee from "react-fast-marquee";

import "./Clients.css";
const Clients = () => {
  return (
    <>
      <div style={{
        display: 'flex',
        flexDirection: 'column'
      }}>
        <h4
          className="buildingh"
          style={{
            marginTop: "4%",
            fontSize: "2.1rem",
            marginBottom: "0rem",
            padding: "2.5%",
            textAlign: "center",
            alignSelf: "center",
            fontWeight: "600",
            fontFamily: "Manrope",
            width: "80%",
            maxWidth: "100%",
            color: "#66bb94",
            paddingBottom: 10
          }}
        >
          Our Key Banks, Clients & Associates
        </h4>
        <Marquee speed={100} gradient={true} pauseOnHover={true}>
          <img className="climg" src={FederalBank} alt="FederalBank" />
          <img className="climg" src={AxisBank} alt="AxisBank" />
          <img className="climg" src={CSBBank} alt="CSBBank" />
          <img className="climg" src={HDFC} alt="HDFC" />
          <img className="climg" src={ICICIBank} alt="ICICIBank" />
          <img className="climg" src={indusind} alt="indusind" />
          <img className="climg" src={idfc} alt="idfc" />
          <img className="climg" src={kotaklogo} alt="kotaklogo" />
          <img className="climg" src={NSDLPayments} alt="NSDLPayments" />
          <img className="climg" src={RBLBank} alt="RBLBank" />
          {/* <img
            className="climg"
            src={sbm}
            alt="sbm"
            style={{ backgroundColor: "red" }}
          /> */}
          <img className="climg" src={YesBank} alt="YesBank" />
        </Marquee>
        <Marquee speed={100} gradient={true} pauseOnHover={true} direction="right">

          <img className="climg" src={bajajfin} alt="Bajaj Finance" />
          <img className="climg" src={Bajaj_Finance_Housing} alt="Bajaj Housing Finance" />
          <img className="climg" src={ABCL} alt="Aditya Birla Capital" />
          <img className="climg" src={Mufin} alt="Mufin Green Finance" />
          <img className="climg" src={Hindon} alt="Hindon Mercantile" />
          <img className="climg" src={BimaPay} alt="Bimapay" />
          <img className="climg" src={Indorient} alt="Indorient Finance" />
          <img className="climg" src={Mobileware} alt="Mobileware" />
          <img className="climg" src={Blostem} alt="Blostem" />
          <img className="climg" src={Auro} alt="Auro Fintech" />
          <img className="climg" src={nas} alt="NAS Digitech" />
          <img className="climg" src={Rythymflows} alt="Rhythm flows" />
          <img className="climg" src={tlGS} alt="TLGS" />
          <img className="climg" src={Ypay} alt="Ypay" />
          <img className="climg" src={FintranXT} alt="FintranXT" />
          <img className="climg" src={Greattr} alt="Greattr" />
          <img className="climg" src={Aatmasamaan} alt="Atmasanman" />
          <img className="climg" src={CTPLio} alt="CTPL.io" />
          <img className="climg" src={sunstone} alt="Sunstone" />
          <img className="climg" src={Fiddypay} alt="Fidypay" />
          <img className="climg" src={nimbbl} alt="Nimmbl" />
        </Marquee>
        {/* <ul>
          <li className="animated zoomIn">
            <div className="back"></div>
            <a className="icon" href="https://www.federalbank.co.in/">
              <img className="climg" src={FederalBank} alt="" />
            </a>
          </li>
          <li className="animated zoomIn">
            <div className="back"></div>
            <a className="icon" href="https://www.indusind.com/">
              <img className="climg" src={indusind} alt="" />
            </a>
          </li>
          <li className="animated zoomIn">
            <div className="back"></div>
            <a className="icon" href="https://www.yesbank.in/">
              <img className="climg" src={YesBank} alt="" />
            </a>
          </li>
          <li className="animated zoomIn">
            <div className="back"></div>
            <a className="icon" href="http://www.idfcfirstbank.com/">
              <img className="climg" src={idfc} alt="" />
            </a>
          </li>
          <li className="animated zoomIn">
            <div className="back"></div>
            <a className="icon" href="https://www.kotak.com/">
              <img className="climg" src={kotaklogo} alt="" />
            </a>
          </li>
          <li className="animated zoomIn">
            <div className="back"></div>
            <a className="icon" href="https://nsdlbank.com/">
              <img className="climg" src={nsdllogo} alt="" />
            </a>
          </li>
          <li className="animated zoomIn">
            <div className="back"></div>
            <a className="icon" href="https://www.rblbank.com/">
              <img className="climg" src={RBLBank} alt="" />
            </a>
          </li>
          <li className="animated zoomIn">
            <div className="back"></div>
            <a className="icon" href="https://www.axisbank.com/">
              <img className="climg" src={AxisBank} alt="" />
            </a>
          </li>
          <li className="animated zoomIn">
            <div className="back"></div>
            <a className="icon" href="https://www.hdfcbank.com/">
              <img className="climg" src={HDFC} alt="" />
            </a>
          </li>
          <li className="animated zoomIn">
            <div className="back"></div>
            <a className="icon" href="https://www.csb.co.in/">
              <img className="climg" src={CSBBank} alt="" />
            </a>
          </li>
          <li className="animated zoomIn">
            <div className="back"></div>
            <a className="icon" href="https://www.signzy.com/">
              <img className="climg" src={signzy} alt="" />
            </a>
          </li>
          <li className="animated zoomIn">
            <div className="back"></div>
            <a className="icon" href="https://www.xtendvalue.com">
              <img className="climg" src={XtendValue} alt="" />
            </a>
          </li>
          <li className="animated zoomIn">
            <div className="back"></div>
            <a className="icon" href="https://techstalwarts.com/">
              <img className="climg" src={Techstalwarts} alt="" />
            </a>
          </li>{" "}
          <li className="animated zoomIn">
            <div className="back"></div>
            <a className="icon" href="https://www.defensanet.com/">
              <img className="climg" src={defen} alt="" />
            </a>
          </li>
          <li className="animated zoomIn">
            <div className="back"></div>
            <a className="icon" href="https://nsdl.co.in/">
              <img className="climg" src={nsdllogo} alt="" />
            </a>
          </li>
          <li className="animated zoomIn">
            <div className="back"></div>
            <a className="icon" href="https://www.gupshup.io/">
              <img className="climg" src={Logo_Gupshup} alt="" />
            </a>
          </li>
          <li className="animated zoomIn">
            <div className="back"></div>
            <a className="icon" href="https://www.selp.fr/en/home/">
              <img className="climg" src={selp} alt="" />
            </a>
          </li>
          <li className="animated zoomIn">
            <div className="back"></div>
            <a className="icon" href="https://transcorpint.com/">
              <img className="climg" src={tran} alt="" />
            </a>
          </li>
          <li className="animated zoomIn">
            <div className="back"></div>
            <a className="icon" href="https://www.card91.io/">
              <img className="climg" src={card91} alt="" />
            </a>
          </li>
          <li className="animated zoomIn">
            <div className="back"></div>
            <a className="icon" href="https://mobilewaretech.com/">
              <img className="climg" src={Mobileware} alt="" />
            </a>
          </li>
          <li className="animated zoomIn">
            <div className="back"></div>
            <a className="icon" href="https://www.vegapay.tech/">
              <img className="climg" src={vega} alt="" />
            </a>
          </li>
          <li className="animated zoomIn">
            <div className="back"></div>
            <a className="icon" href="https://www.bajajfinserv.in/">
              <img className="climg" src={bajajfin} alt="" />
            </a>
          </li>
          <li className="animated zoomIn">
            <div className="back"></div>
            <a className="icon" href="https://www.bajajhousingfinance.in/">
              <img className="climg" src={Bajaj_Finance_Housing} alt="" />
            </a>
          </li>
          <li className="animated zoomIn">
            <div className="back"></div>
            <a className="icon" href="https://www.adityabirlacapital.com/">
              <img className="climg" src={ABCL} alt="" />
            </a>
          </li>
          <li className="animated zoomIn">
            <div className="back"></div>
            <a className="icon" href="https://mufingreenfinance.com/">
              <img className="climg" src={Mufin} alt="" />
            </a>
          </li>
          <li className="animated zoomIn">
            <div className="back"></div>
            <a className="icon" href="https://hindon.co/">
              <img className="climg" src={Hindon} alt="" />
            </a>
          </li>
          <li className="animated zoomIn">
            <div className="back"></div>
            <a className="icon" href="https://www.rupeecircle.com/">
              <img className="climg" src={Rupee} alt="" />
            </a>
          </li>
          <li className="animated zoomIn">
            <div className="back"></div>
            <a className="icon" href="https://bimapay.in/">
              <img className="climg" src={BimaPay} alt="" />
            </a>
          </li>
          <li className="animated zoomIn">
            <div className="back"></div>
            <a className="icon" href="https://www.indorient.in/">
              <img className="climg" src={Indorient} alt="" />
            </a>
          </li>
          <li className="animated zoomIn">
            <div className="back"></div>
            <a className="icon" href="https://mobilewaretech.com/">
              <img className="climg" src={Mobileware} alt="" />
            </a>
          </li>
          <li className="animated zoomIn">
            <div className="back"></div>
            <a className="icon" href="https://blostem.com/">
              <img className="climg" src={Blostem} alt="" />
            </a>
          </li>{" "}
          <li className="animated zoomIn">
            <div className="back"></div>
            <a className="icon" href="https://www.nasdigital.in/">
              <img className="climg" src={nas} alt="" />
            </a>
          </li>
          <li className="animated zoomIn">
            <div className="back"></div>
            <a className="icon" href="https://rhythmflows.com/">
              <img className="climg" src={Rythymflows} alt="" />
            </a>
          </li>
          <li className="animated zoomIn">
            <div className="back"></div>
            <a className="icon" href="https://tlgs.consulting/">
              <img className="climg" src={tlGS} alt="" />
            </a>
          </li>
          <li className="animated zoomIn">
            <div className="back"></div>
            <a className="icon" href="https://www.extolab.com">
              <img className="climg" src={Extolabs} alt="" />
            </a>
          </li>
          <li className="animated zoomIn">
            <div className="back"></div>
            <a className="icon" href="https://ypaycard.com/">
              <img className="climg" src={Ypay} alt="" />
            </a>
          </li>
          <li className="animated zoomIn">
            <div className="back"></div>
            <a className="icon" href="https://greattr.com/">
              <img className="climg" src={Greattr} alt="" />
            </a>
          </li>
          <li className="animated zoomIn">
            <div className="back"></div>
            <a className="icon" href="https://theimperative.in/">
              <img className="climg" src={Imparitive} alt="" />
            </a>
          </li>
          <li className="animated zoomIn">
            <div className="back"></div>
            <a className="icon" href="http://www.atmasamman.com/">
              <img className="climg" src={Aatmasamaan} alt="" />
            </a>
          </li>
          <li className="animated zoomIn">
            <div className="back"></div>
            <a className="icon" href="https://ctpl.io">
              <img className="climg" src={CTPLio} alt="" />
            </a>
          </li>
          <li className="animated zoomIn">
            <div className="back"></div>
            <a className="icon" href="https://sunstonetech.net/">
              <img className="climg" src={sunstone} alt="" />
            </a>
          </li>
          <li className="animated zoomIn">
            <div className="back"></div>
            <a className="icon" href="https://www.fidypay.com/">
              <img className="climg" src={Fiddypay} alt="" />
            </a>
          </li>
          <li className="animated zoomIn">
            <div className="back"></div>
            <a className="icon" href="hhttps://nimbbl.biz">
              <img className="climg" src={nimbbl} alt="" />
            </a>
          </li>
          <li className="animated zoomIn">
            <div className="back"></div>
            <a className="icon" href="/">
              <img className="climg" src={Auro} alt="" />
            </a>
          </li>
          <li className="animated zoomIn">
            <div className="back"></div>
            <a className="icon" href="/">
              <img className="climg" src={FintranXT} alt="" />
            </a>
          </li>
          {/* <li className="animated zoomIn">
          <div className="back"></div>
          <a className="icon" href="https://www.sbmbank.co.in/">
            <img className="climg" src={sbm} alt="" />
          </a>
        </li> 
        </ul> */}
      </div>
    </>
  );
};

export default Clients;
