import React, { useState } from "react";
import "./faq.css";

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqItems = [
    {
      question: "What is transaction banking?",
      answer:
        "Transaction banking, also known as treasury services or cash management, refers to a range of banking services that facilitate financial transactions and manage cash flows for corporations, financial institutions, governments, and other large organizations.",
    },
    {
      question: "What are the key components of transaction banking?",
      answer:
        "The key components of transaction banking include cash management, trade finance, payments and collections, treasury services, clearing and settlement, and information and reporting.",
    },
    {
      question: "Who offers transaction banking services?",
      answer:
        "Transaction banking services are typically offered by large banks and financial institutions with specialized expertise and infrastructure to handle complex financial operations.",
    },
    {
      question:
        "What are the benefits of transaction banking for organizations?",
      answer:
        "By leveraging transaction banking services, organizations can improve their cash flow management, reduce operational costs, mitigate risks, and enhance overall financial efficiency.",
    },
    {
      question: "How can transaction banking help organizations?",
      answer:
        "Transaction banking helps organizations by providing efficient, secure, and streamlined financial solutions to support their day-to-day operational needs, optimize cash flows, and enhance financial control and risk management.",
    },
    {
      question: "What is an escrow arrangement?",
      answer:
        "An escrow arrangement is a legal mechanism where a neutral third party, known as the escrow agent, holds funds, documents, or other assets on behalf of two or more parties involved in a transaction. It ensures the secure transfer of funds or assets once the agreed-upon conditions are met.",
    },
    {
      question: "What is the purpose of escrow in India?",
      answer:
        "Escrow serves as a trust mechanism to protect the interests of both the buyer and the seller in a transaction. It ensures that the agreed-upon terms and conditions are met before the funds or assets are released.",
    },
    {
      question: "Who is the escrow agent?",
      answer:
        "The escrow agent is an independent third party, such as a bank, financial institution, or specialized escrow service provider. The escrow agent is responsible for safeguarding the escrowed funds or assets and acts in a neutral capacity as per the instructions in the escrow agreement.",
    },
    {
      question: "What types of escrow arrangements are common in India?",
      answer:
        "Escrow arrangements are commonly used in various scenarios, including real estate transactions, mergers and acquisitions, payment security in international trade, and crowdfunding campaigns. Each type of escrow may have specific requirements and procedures.",
    },
    {
      question:
        "What is the regulatory framework for escrow services in India?",
      answer:
        " Escrow services in India may be governed by various regulations, including the Indian Contract Act, 1872, and guidelines set by the Reserve Bank of India (RBI). It is important to comply with applicable laws and regulations while setting up and conducting escrow transactions.",
    },
    {
      question: "What is the general process of an escrow arrangement?",
      answer:
        "The escrow process involves several stages, which may vary depending on the transaction. Typically, it includes agreement, deposit, verification, release, and dispute resolution. The specifics are outlined in the escrow agreement.",
    },
    {
      question: "How can escrow services benefit transactions in India?",
      answer:
        "Escrow services play a crucial role in ensuring trust, security, and transparency in various transactions. They provide a mechanism for parties to protect their interests and ensure that the transaction's conditions are met before funds or assets are released.",
    },
    {
      question:
        "What should I consider when setting up an escrow arrangement in India?",
      answer:
        "When considering an escrow arrangement, it is essential to consult with legal and financial professionals to ensure compliance with relevant laws and regulations. This helps protect the interests of all parties involved and ensures a smooth and secure transaction process.",
    },
    {
      question: "What is cash management?",
      answer:
        "Cash management involves managing and optimizing cash flows, liquidity, and working capital for clients. It includes account management, cash pooling, liquidity management, payment processing, collections, and disbursements.",
    },
    {
      question: "What is trade finance?",
      answer:
        "Trade finance services support international trade activities, such as import and export transactions. They may include letters of credit, trade finance facilities, supply chain financing, export financing, and trade document management.",
    },
    {
      question: "What services are provided for payments and collections?",
      answer:
        "Transaction banks provide payment solutions for efficient domestic and cross-border payments. This includes payment initiation, payment processing, fund transfers, bulk payments, direct debit, and electronic funds transfer. Collections services focus on automating and streamlining the collection of receivables.",
    },
    {
      question: "What are treasury services in transaction banking?",
      answer:
        "Treasury services assist clients in managing their treasury operations and financial risk. These services may include foreign exchange transactions, hedging instruments, interest rate management, investment management, and risk mitigation solutions.",
    },
    {
      question: "What is clearing and settlement?",
      answer:
        "Clearing and settlement services ensure the timely and accurate settlement of financial transactions, such as securities trading, interbank transfers, and foreign exchange transactions.",
    },
    {
      question:
        "How does transaction banking provide information and reporting?",
      answer:
        "ransaction banking provides clients with real-time access to financial information, reporting, and analytics. This allows clients to monitor their cash positions, transaction history, balances, and other financial data.",
    },
  ];

  return (
    <div className="faq-container">
      <br />
      <br />
      <br />
      <h1 className="faq-title">Knowledge Bank</h1>
      <div className="faq-list">
        {faqItems.map((item, index) => (
          <div
            className={`faq-item ${activeIndex === index ? "active" : ""}`}
            key={index}
            onClick={() => handleAccordionClick(index)}
          >
            <div className="faq-question">{item.question}</div>
            {activeIndex === index && (
              <div className="faq-answer">{item.answer}</div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Faq;
