import React from "react";
import emptyimg from "./assets/feature2.svg.png";
import "./About.scss";
import Advisors from "./Advisors";
import Marquee from "react-fast-marquee";
import VaibhavImg from "./assets/vaibhav.png";
import Lavin from "./assets/lavin.png";
import JP from "./assets/jp.png";
import Akshat from "./assets/akshat.png";
import transbnklogo from "./assets/TransBnk Logo TM (4).png";

import Sachin from "./assets/sachin.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";

function About() {
  const list = [
    {
      name: "Vaibhav Tambe",
      img: VaibhavImg,
      link: "https://www.linkedin.com/in/vaibhav-tambe/",
      position: "Co-Founder & CEO",
      role: "Strategy, Product & Business",
      desc: `14+ Years | Ex IndusInd, SBI Bank, TCS, iServeU | MBA- IMT,
          B.E (Telecommunication) | Most Admired Fintech Professional
          2022 (ET Now, CMO, World BFSI Congress) |50 Most Influential
          Payment Professional 2020 (ET Now, World Payments Council)`,
      email: "vaibhav.tambe@transbnk.co.in",
    },
    {
      name: "Lavin Kotian",

      img: Lavin,
      link: "https://www.linkedin.com/in/lavin-kotian-6a3b1520/",
      position: "Co-Founder & CTO",
      role: "Technology & Architecture",
      desc: ` 20+ years of experience in diverse industries | Built
          expertise in Global Turnkey Sales & Operations. Global &
          Indian enterprise solutions | Former IT head of Aditya Birla
          Finance | Product Delivery & Technology Architecture
        `,
      email: "lavin.kotian@transbnk.co.in",
    },
    {
      name: "Pulak Jain",
      img: JP,
      link: "https://www.linkedin.com/in/pulak-jain-127776193/",
      position: "Co-Founder & CBO",
      role: "Sales & Strategy",
      desc: ` 15+ Years |Transaction Banker & Fintech Consultant| Solution Architect in Product Lifecycle through design modeling, development & Implementation for Transaction banking products
            Business Development & Sales
        `,
      email: "akshat.gupta@transbnk.co.in",
    },
    {
      name: "Sachin Gupta",
      img: Sachin,
      link: "https://www.linkedin.com/in/sachin-gupta-6a014813/",
      position: "Co-Founder & CPO",
      role: "Products and Delivery",
      desc: ` IIM Calcutta ‘11  | Delhi College of Engineering ’06 
            14+ years Transaction Banking experience as Solution Architect in Product Lifecycle Management through design modeling, development & Implementation
        `,
      email: "akshat.gupta@transbnk.co.in",
    },
    {
      name: "Akshat Gupta",
      img: Akshat,
      link: "https://www.linkedin.com/in/akshat-gupta-968b818/",
      position: "Legal Counsel",
      role: " Legal & Compliance",
      desc: `     12+ Years Experience in legal, compliance & consulting |
            Supreme Court & High Court Lawyer| Honorary advisor to BRICS
            | LLM – Warwick University, England, UK, BA.LLB(H), Amity
            Law School
          `,
      email: "akshat.gupta@transbnk.co.in",
    },
  ];

  // const logos = [
  //     "./assets/auro.98f50b020862c375bbcc.png.png",
  //     "./assets/blostem.ef7a9957ae05c2b0c5c3.png.png",
  //     "./assets/xtendvalue.e8b6621d488ae467c583.png.png",
  //     "./assets/signzy.4471d63fa0c459f2564e.png.png",
  //     "./assets/greattr.8f4754e8abad59f2c46534beba59321f.svg.png"
  // ];

  return (
    <div className="About">
      <div className="About1">
        <div className="About1left">
          <div className="contentwrapAboutleft">
            <p className="headingAbout">
              At TransBnk, we drive, digitize & re-engineer Transaction Banking
            </p>
            {/* <p className="contentAbout">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem
              ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum
              dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit
              amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet,
              consectetur adipiscing elit.
            </p> */}
          </div>
        </div>
        <div className="About1right">
          <img
            src={transbnklogo}
            alt="transbnk logo"
            className="About1rightimg"
          />
        </div>
      </div>
      <h4 className="buildingh">
        Building stronger partnerships, driving success together
      </h4>
      <Marquee speed={100} gradient={true} pauseOnHover={true}>
        <img
          className="company-icon"
          src={require("./assets/auro.98f50b020862c375bbcc.png.png")}
          alt="Company 1"
        />
        <img
          className="company-icon"
          src={require("./assets/blostem.ef7a9957ae05c2b0c5c3.png.png")}
          alt="Company 2"
        />
        <img
          className="company-icon"
          src={require("./assets/xtendvalue.e8b6621d488ae467c583.png.png")}
          alt="Company 3"
        />
        <img
          className="company-icon"
          src={require("./assets/signzy.4471d63fa0c459f2564e.png.png")}
          alt="Company 4"
        />
        <img
          className="company-icon"
          src={require("./assets/greattr.8f4754e8abad59f2c46534beba59321f.svg.png")}
          alt="Company 5"
        />
      </Marquee>
      <div className="About3">
        <div className="About3left">
          <p className="heading3About">
            At TransBnk, we combine the expertise of seasoned bankers and
            technologists with over 50 years of collective experience in
            Transaction Banking.
          </p>
        </div>
        <div className="About3right">
          <p style={{ padding: "0%", color: "#475066", margin: "0%" }}>
            This allows us to deliver comprehensive platform solutions that
            empower the banking ecosystem. Our range of services includes
            digital lending, supply chain solutions, marketplace solutions,
            capital market services, and custodial services.{" "}
          </p>{" "}
        </div>
      </div>
      <h4 className="buildingh2">
        Meet the Members of <br />
        Our Dynamic Crew
      </h4>
      <p style={{ margin: "0%", fontSize: "1rem", textAlign: "center" }}>
        Empowering success through teamwork and collaboration
      </p>
      {/*  Our team Section */}
      <section
        id="team"
        class="team content-section"
        style={{ margin: "0%", marginTop: "20px" }}
      >
        <div class="container ">
          <div class="row text-center ">
            <div class="container">
              <div class="row jccx">
                {list.map((n) => (
                  <div class="col-md-2 ">
                    <div class="team-member">
                      <figure>
                        <img src={n.img} alt="" class="img-responsive" />
                        <figcaption>
                          <p>{n.desc}</p>
                          <a href={n.link} aria-label="LinkedIn">
                            <FontAwesomeIcon
                              icon={faLinkedin}
                              color="white"
                              style={{ fontSize: "24px" }}
                            />
                          </a>
                        </figcaption>
                      </figure>
                      <h4>{n.name}</h4>
                      <p
                        style={{
                          margin: "0%",
                          color: "#66BB94",
                          fontWeight: "900",
                        }}
                      >
                        {n.position}
                      </p>
                      <p style={{ margin: "0%", fontSize: "0.81rem" }}>
                        {n.role}
                      </p>
                    </div>
                    {/*  /.team-member*/}
                  </div>
                ))}
              </div>
              {/*  /.row */}
            </div>
            {/*  /.container */}
          </div>
          {/*  /.row */}
        </div>
        {/*  /.container */}
      </section>
      {/*  /.our-team */}
      <div className="About5">
        <div className="About5left">
          <p className="heading3About">
            Achieving Greatness with the Support of Our Exceptional Advisors
          </p>
        </div>
        <div className="About5right">
          <p style={{ padding: "0%", color: "#475066", margin: "0%" }}>
            As our company continues to grow and evolve, it is important that we
            have a strong and dedicated advisory board in place to provide
            guidance and support. Our advisory board members bring a wealth of
            experience and expertise in a variety of fields, and their input is
            invaluable as we make strategic decisions and navigate the
            challenges of the business world. Their diverse perspectives and
            ability to think creatively and critically allow us to approach
            problems from different angles and develop innovative solutions. We
            are grateful for their ongoing commitment to our company and look
            forward to working closely with them in the coming months and years.{" "}
          </p>{" "}
        </div>
      </div>
      <Advisors />
    </div>
  );
}

export default About;
