import React, { useState } from "react";
import "./NewsSection.css";
import Indorient from "./assets/Logo/Indorient.png";
const NewsSection = () => {
  const [darkMode, setDarkMode] = useState(false);

  const toggleMode = () => {
    setDarkMode(!darkMode);
  };

  // Card data
  const cards = [
    {
      image: "https://drive.google.com/uc?id=1lEodhOYshMElfezX3gsdHB_96l9MxFpW",
      tags: [],
      title:
        "Announcing TransBnk - Transaction Banking Platform partnership with Block Stack",
      category: "",
      categoryLink: "#",
      text: "Block Stack, a platform for blockchain technology solutions, and TransBnk, a digital banking-based firm, has announced their partnership.It’s believed that the partnership aims to contribute towards blockchain-based financial services.",
      date: "June 1, 2023",
      readMoreLink:
        "https://www.financialexpress.com/business/blockchain-block-stack-collaborates-with-transbnk-to-implement-blockchain-backed-banking-facilities-3110343/",
    },
    {
      image: "https://drive.google.com/uc?id=1DUpPa-NuSANyS2o7ZQsj0vDJ-oOBEjn2",
      tags: [],
      title:
        "Exploring the benefits of transactional banking for small businesses",
      category: "",
      categoryLink: "#",
      text: "Transactional banking services benefit small businesses with simplified transactions, increased efficiency, improved cash flow management, reduced costs, and access to financing. The involvement of fintech and banks presents growth opportunities in the industry.",
      date: "May 25, 2023",
      readMoreLink:
        "https://bfsi.eletsonline.com/exploring-the-benefits-of-transactional-banking-for-small-businesses/",
    },

    {
      image: "https://drive.google.com/uc?id=1I54TgKTIPUB-kIOZQ_W1_FsbNGXGfuS3",
      tags: [],
      title:
        "Our CEO Vaibhav Tambe got featured as Top Fintech Leader of 2023 by TradeFlock",
      category: "",
      categoryLink: "#",
      text: "With 15+ years of professional experience, Vaibhav Tambe, Co-Founder & CEO, TransBnk - Transaction Banking Platform, is a suitable inclusion to the list of the best Fintech Leaders in India 2023. Vaibhav envisions changing India’s transactional banking landscape with his innovative and safe solutions and is credited for building one of India’s fastest-growing and most secure transactional banking companies.",
      date: "May 25, 2023",
      readMoreLink:
        "https://tradeflock.com/magazine-fintech-leaders-in-india-2023/",
    },
    {
      image: "https://drive.google.com/uc?id=1eze9trxWpHlhv-vdFVHOM7mTPhaar7PG",
      tags: [],
      title:
        "Vaibhav Tambe: Building a Successful Transaction Banking Business",
      category: "",
      categoryLink: "#",
      text: "Vaibhav Tambe, CEO of TransBnk, aims to revolutionize India's transactional banking sector by leveraging innovative technologies and building a secure infrastructure. With a focus on people, domain knowledge, and calculated risk-taking, TransBnk strives to streamline banking processes and ensure the safety of transactions. The company is exploring new use cases and plans to expand globally in the near future.",
      date: "May 18, 2023",
      readMoreLink:
        "https://ceoreviewmagazine.com/most-dynamic-ceos-to-watch-in-2023/vaibhav-tambe-co-founder-ceo-transbnk/",
    },
    {
      image: "https://drive.google.com/uc?id=16cgjx_mV2b9Gt1URhk8rkEp_SK1FfEO_",
      tags: [],
      title:
        "TRANSACTION BANKING PLATFORM ‘TRANSBNK’ PARTNERS WITH MUFIN GROUP",
      category: "",
      categoryLink: "#",
      text: "Mufin Group has been working in the financial domain since 1985 through its parent company Hindon Mercantile Limited",
      date: "MAY 04, 2023",
      readMoreLink:
        "https://investmentguruindia.com/IndustryNews/-Transaction-Banking-Platform-TransBnk-partners-with-Mufin-Group",
    },
    {
      image: "https://drive.google.com/uc?id=1gLoImoCgTk2dk_OG354XiAdmegQNVm62",
      tags: [],
      title: "Fintech Yatra represents TransBnk at the Everest Base Camp",
      category: "",
      categoryLink: "#",
      text: "We are thrilled to share that Team The Fintech Yatra at The Fintech Meetup scaled up the Everest Base Camp at 17,598 ft amidst Fintech Yatra 2023, proudly representing TransBnk - Transaction Banking Platform at this scale, a once-in-a-lifetime experience in our journey",
      date: "April 14, 2023",
      readMoreLink:
        "https://www.linkedin.com/feed/update/urn:li:activity:7052510635284172800",
    },
    {
      image: "https://drive.google.com/uc?id=16Jw11soTGqlkgLYsORog3rEpDM_K91Rg",
      tags: [],
      title: "How digital Escrow banking has an edge over traditional banking",
      category: "",
      categoryLink: "#",
      text: "Read the entire article by The Times Of India where Vaibhav Tambe founder of TransBnk - Transaction Banking Platform have put various points all together.",
      date: "April 7, 2023",
      readMoreLink:
        "https://timesofindia.indiatimes.com/blogs/voices/how-digital-escrow-banking-has-an-edge-over-traditional-banking/",
    },

    {
      image: "https://drive.google.com/uc?id=18Komr1P07cwJrcZe80wvoWuXgCurN57G",
      tags: [],
      title:
        "Explainer: Why crypto has come under India's anti-money laundering law",
      category: "",
      categoryLink: "#",
      text: "A recent statement from Lavin Kotian CTO of TransBnk - Transaction Banking Platform on 'Why crypto has come under India's anti-money laundering law' in The Times Of India",
      date: "March 9, 2023",
      readMoreLink:
        "https://timesofindia.indiatimes.com/business/cryptocurrency/bitcoin/explainer-why-crypto-has-come-under-indias-anti-money-laundering-law/articleshow/98515196.cms?from=mdr",
    },
    {
      image: "https://drive.google.com/uc?id=123QJFESaxEgC2fUTjQf8ifuuCXfVlPfv",
      tags: [],
      title: "How and when should you exit from bad-performing ULIP?",
      category: "",
      categoryLink: "#",
      text: "Recent statement from Sachin Gupta CPO of TransBnk - Transaction Banking Platform on right time to take exit from ULIP at Mint",
      date: "March 2, 2023",
      readMoreLink:
        "https://www.livemint.com/money/personal-finance/how-and-when-should-you-exit-from-ulip-11677604497215.html",
    },
    {
      image: "https://drive.google.com/uc?id=1Sz8T4TZc6Uy9sMermm2bAA_VfCFaV95J",
      tags: [],
      title:
        "Investor sentiment shaken as India imposes and repeals ban on digital lending apps",
      category: "",
      categoryLink: "#",
      text: "Recent statement from Pulak Jain CBO of TransBnk - Transaction Banking Platform on Lending space covered by DealStreetAsia",
      date: "Feb 24, 2023",
      readMoreLink:
        "https://www.dealstreetasia.com/stories/india-digital-lending-regulations-329699",
    },
    {
      image: "https://drive.google.com/uc?id=1WawjO4nNCFZ7xdtvEuVOIpLRtwTHXHPe",
      tags: [],
      title: "Our CEO speaks at a panel discussion by NewsX",
      category: "",
      categoryLink: "#",
      text: "A panel discussion organised by NewsX our founder Vaibhav Tambe spoke about the recent cross border transactions development between India and Singapore",
      date: "Feb 21, 2023",
      readMoreLink: "https://www.youtube.com/watch?v=MC8fGVSKkK4",
    },
  ];

  return (
    <section className={`focus-section ${darkMode ? "dark-mode" : ""}`}>
      <div className="container-lg py-2">
        <button
          className={`btn btn-${
            darkMode ? "light" : "dark"
          } btn-sm text-uppercase`}
          onClick={toggleMode}
          id="btnValue"
          type="button"
        >
          {darkMode ? "Light mode" : "Dark mode"}
        </button>
      </div>

      <div className="container-lg py-5">
        <div className="container px-0">
          <div className="row">
            <div className="col">
              <h2 className="title-section">News & Media</h2>
            </div>
            {/* <div className="col">
              {/* <span className="title-section d-flex justify-content-end">
                <a className="btn btn-archive btn-sm" href="#" role="button">
                  Archive
                </a>
              </span> 
            </div> */}
          </div>
        </div>

        <div className="row row-cols-1 row-cols-md-3 g-4">
          {cards.map((card, index) => (
            <div className="col" key={index}>
              <div className="card shadow-sm h-100">
                <div className="card-image">
                  <div className="hover-text">
                    <img src={card.image} className="card-img-top" alt="..." />
                    <div className="bottom-right-tag text-uppercase">
                      {card.tags.map((tag, index) => (
                        <a key={index} href="#">
                          {tag}
                        </a>
                      ))}
                    </div>
                  </div>
                  <div className="image-overlay"></div>
                </div>
                <div className="card-body">
                  <h3 className="card-title">{card.title}</h3>
                  <div className="text-left my-2">
                    {card.category && (
                      <div className="sub-cat text-truncate">
                        <span className="badge rounded-pill bg-category text-uppercase">
                          {card.category}
                        </span>{" "}
                        <a href={card.categoryLink}>{card.category}</a>
                      </div>
                    )}
                  </div>
                  <p className="card-text">{card.text}</p>
                </div>
                <div className="card-footer py-3">
                  <div className="card-footer__info">
                    <span>{card.date}</span>
                    <span className="read-more">
                      <a
                        className="text-uppercase read-more-1"
                        href={card.readMoreLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Read more
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default NewsSection;
