import React from 'react';
import { Link } from 'react-router-dom';
import './Sidebar.scss'

const Sidebar = () => {
    return (
        <div className="sidebar">
            <p className="sidebar-link2">Overview</p>
            <Link to="/api-hub/introduction" className="sidebar-link">Introduction</Link>
            <Link to="/Register" className="sidebar-link">Login or Register</Link>
            <hr />
            <p className="sidebar-link2">API stack</p>
            <Link to="/api-hub/bank" className="sidebar-link">Banking</Link>
            <Link to="/api-hub/ancillary" className="sidebar-link">Lending Ecosystem Enabler</Link>
            <Link to="/api-hub/payments" className="sidebar-link">Recurring Payments</Link>
            <Link to="/api-hub/docuflow" className="sidebar-link">Onboarding</Link>

        </div>
    );
};

export default Sidebar;
